import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { getUser, getArtifactLibraryItems, getPublicUserDetailsByAddress, getPublicArtifactLibraryItems } from '../../api/hasura';
import { Box } from '@mui/material';
import { Button, Text, Avatar, Card } from '@radix-ui/themes';
import { PersonIcon, Pencil1Icon, ArrowLeftIcon } from '@radix-ui/react-icons';
import { toChecksumAddress, formatJoinDate } from '../../utils/commonUtils';
import { useAppState } from '../../state/AppStateProvider';
import { LibraryArtifact } from '../../widgets/StrategyLibraryWidget';
import { PageLoadingView } from '../simulation/create-simulation/PageLoadingView';
import { ArtifactItem } from '../strategy/ArtifactItem';
import { styled } from '@mui/material/styles';
import { popBackStack, pushCurrentRoute } from '../../utils/navUtils';

// Wanted different styling compared to FullScreenContainer
const ProfileContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    max-width: 1400px;
    width: 100%;
    margin: 64px auto 0;
    height: calc(100vh - 64px);
    overflow: hidden;
`;

const UserProfile = () => {
    const { walletAddress } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAppState();

    // Check if this is the current user's profile
    const isCurrentUserProfile = user?.publicKey.toLowerCase() === walletAddress?.toLowerCase();

    // Fetch user public data
    const { data: profileData, isLoading: isLoadingUser } = useSWR(
        walletAddress ? `user-${walletAddress}` : null,
        async () => {
            const publicData = await getPublicUserDetailsByAddress(walletAddress!);
            return publicData.data;
        },
        {
            revalidateOnFocus: false
        }
    );

    // Separate SWR hooks for artifacts based on profile type
    const { data: privateArtifacts, isLoading: isLoadingPrivateArtifacts } = useSWR(
        isCurrentUserProfile ? `private-artifacts-${user?.id}` : null,
        async () => {
            const allArtifacts = await getArtifactLibraryItems();
            return allArtifacts?.filter(
                (artifact: LibraryArtifact) => artifact.author === user?.id
            );
        },
        {
            revalidateOnFocus: false
        }
    );

    const { data: publicArtifacts, isLoading: isLoadingPublicArtifacts } = useSWR(
        !isCurrentUserProfile ? `public-artifacts-${walletAddress}` : null,
        async () => {
            const artifacts = await getPublicArtifactLibraryItems(user?.id || '');
            return artifacts
                ?.filter((artifact: any) => 
                    artifact.author_wallet_address?.toLowerCase() === walletAddress?.toLowerCase()
                )
                .map((artifact: any) => ({
                    ...artifact,
                    is_public: true
                }));
        },
        {
            revalidateOnFocus: false
        }
    );

    const artifacts = isCurrentUserProfile ? privateArtifacts : publicArtifacts;
    const isLoadingArtifacts = isCurrentUserProfile ? isLoadingPrivateArtifacts : isLoadingPublicArtifacts;

    const handleBack = () => {
        popBackStack(navigate, location);
    };

    const handleEditProfile = () => {
        // Use replace navigation to collapse internal transitions
        navigate("/settings/profile", { replace: true });
    };

    const displayName = profileData?.main_name_service ||
        (profileData?.wallet_address ? toChecksumAddress(profileData.wallet_address) : walletAddress);

    if (isLoadingUser) {
        return (
            <ProfileContainer>
                <PageLoadingView
                    fullscreen
                    title="Loading Profile..."
                />
            </ProfileContainer>
        );
    }

    return (
        <ProfileContainer>
            {/* Back Button */}
            <Box sx={{
                display: 'flex',
                gap: 6,
                width: '100%',
                marginBottom: 4
            }}>
                <Button variant="outline" onClick={handleBack}>
                    <ArrowLeftIcon />
                    Back
                </Button>
            </Box>

            {/* Main Content */}
            <Box sx={{
                display: 'flex',
                gap: 6,
                width: '100%',
                height: 'calc(100% - 48px)',
                overflow: 'hidden'
            }}>
                {/* Left Column */}
                <Box sx={{
                    width: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    position: 'sticky',
                    top: '32px',
                    alignSelf: 'flex-start'
                }}>
                    {/* Profile Picture */}
                    <Box sx={{
                        width: 240,
                        height: 240,
                        borderRadius: '50%',
                        overflow: 'hidden',
                        backgroundColor: 'var(--gray-4)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 2,
                        userSelect: 'none', // Prevents text/image selection
                        WebkitUserDrag: 'none', // Prevents image dragging
                        transition: 'transform 0.2s ease',
                        '&:hover': {
                            transform: profileData?.profile_url ? 'scale(1.05)' : 'none', // Subtle zoom effect on hover
                        }
                    }}>
                        {profileData?.profile_url ? (
                            <img
                                src={profileData.profile_url}
                                alt="Profile"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    userSelect: 'none', // Prevents selection
                                }}
                                onError={(e) => {
                                    e.currentTarget.src = '';
                                    e.currentTarget.style.display = 'none';
                                }}
                                draggable={false}
                            />
                        ) : (
                            <PersonIcon width={120} height={120} />
                        )}
                    </Box>

                    {/* ENS or Address */}
                    <Text size="2" weight="bold" style={{
                        wordBreak: 'break-all',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        {displayName}
                    </Text>

                    {/* Username if exists */}
                    {profileData?.username && (
                        <Text size="3" color="gray">
                            @{profileData.username}
                        </Text>
                    )}

                    {/* Edit Profile Button */}
                    {isCurrentUserProfile && (
                        <Button
                            variant="soft"
                            onClick={handleEditProfile}
                        >
                            <Pencil1Icon />
                            Edit Profile
                        </Button>
                    )}

                    {/* Bio if exists */}
                    {profileData?.bio && (
                        <Box sx={{ width: '100%' }}>
                            <Text size="2" style={{ marginTop: '16px' }}>
                                {profileData.bio}
                            </Text>
                        </Box>
                    )}

                    {/* Join Date */}
                    <Box sx={{ width: '100%' }}>
                        {profileData?.created_at && (
                            <Text size="2" color="gray">
                                Joined {formatJoinDate(profileData.created_at)}
                            </Text>
                        )}
                    </Box>
                </Box>

                {/* Right Column - Strategies */}
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Box sx={{ mb: 4 }}>
                        <Text size="6" weight="bold">
                            Strategies
                        </Text>
                    </Box>
                    <Box sx={{
                        flex: 1,
                        overflow: 'auto',
                        padding: '1px'
                    }}>
                        <Card style={{
                            padding: 0,
                            height: 'fit-content',
                            minHeight: '100%'
                        }}>
                            {isLoadingArtifacts ? (
                                <Box sx={{ p: 3, textAlign: 'center' }}>
                                    <Text color="gray">Loading strategies...</Text>
                                </Box>
                            ) : artifacts?.length ? (
                                artifacts.map((artifact: any) => (
                                    <ArtifactItem
                                        key={artifact.id}
                                        artifact={artifact}
                                    />
                                ))
                            ) : (
                                <Box sx={{ p: 3, textAlign: 'center' }}>
                                    <Text color="gray">
                                        {isCurrentUserProfile
                                            ? 'No strategies found'
                                            : 'No public strategies found'}
                                    </Text>
                                </Box>
                            )}
                        </Card>
                    </Box>
                </Box>
            </Box>
        </ProfileContainer>
    );
};

export default UserProfile;