import React from "react";
import { useNavigate } from "react-router-dom";
import { LDKeyAgentPnL, LDKeyAgents } from "../../components/layout/Sidebar";
import { useAppState } from "../../state/AppStateProvider";
import { PageContentContainer } from "../styled/styled";
import { Box } from "@mui/material";

import { Flex, Text, Table, Button, Card, Spinner } from "@radix-ui/themes";
import useSWR from "swr";
import { getLiveAgents, fetchAllAgentsPnl } from "../../api/hasura";
import { AgentStatus } from "../../components/AgentStatus";
import { formatMonetaryValue } from "../../utils/commonUtils";
import { ChainBadge } from "../wallets/ChainBadge";

// Mock data for dashboard
const mockDashboardData = {
    totalProfitLoss: 2500,
    activeAgents: 2,
    totalBalance: 22500,
};

export const DashboardMetric = ({ label, value, isProfitLoss = false, isUsdValue = false, isPercentage = false }: {
    label: string;
    value: string | number;
    isProfitLoss?: boolean;
    isUsdValue?: boolean;
    isPercentage?: boolean;
}) => {
    const valueColor = isProfitLoss
        ? Number(value) >= 0 ? "green" : "red"
        : undefined;

    return (
        <Flex style={{ marginRight: "24px" }}>
            <Flex direction="column" align="start" gap="2">
                <Text size="2" color="gray">{label}</Text>
                <Text size="6" weight="bold"
                    color={valueColor}>{isUsdValue ? "$" : ""}{formatMonetaryValue(value, { maxFractionDigits: 2 })}{isPercentage ? "%" : ""}</Text>
            </Flex>
        </Flex>
    );
};

const AgentsDashboard = () => (
    <Flex gap="4" wrap="wrap">
        {/*<DashboardMetric*/}
        {/*    label="Total Profit/Loss"*/}
        {/*    value={"0"}*/}
        {/*    isProfitLoss={true}*/}
        {/*    isUsdValue*/}
        {/*/>*/}
        {/*<DashboardMetric label="Active Agents" value={"-"}/>*/}
        {/*<DashboardMetric label="Total Balance" value={`-`}/>*/}
    </Flex>
);

interface Agent {
    id: string;
    name: string;
    status: string;
    artifact_id_version_id: string;
    strategy_version: string | null;
    created_at: string;
    updated_at: string;
    config: string;
    user_id: string;
}

const remapAgents = (agents: any) => {
    try {
        return agents?.map((agent: any) => {
            // Extract platformWallets, safeAccountName, and chainId
            const platformWallets = agent.config.agent_config.ALMANAK_PLATFORM_WALLETS;
            const safeAccountName = platformWallets[0].SAFE_ACCOUNT_NAME;
            const chainId = platformWallets[0].CHAIN_ID;

            // Extract the first key from strategy_configs (dynamic key)
            const artifact = agent?.artifact_id_version?.artifact;
            const strategyVersion = agent?.artifact_id_version?.name;
            const strategyName = artifact?.name;

            // Return the remapped agent with additional fields
            return {
                ...agent, // Spread the original agent properties
                safeAccountName, // Add safeAccountName
                chainId, // Add chainId
                strategyName, // Add strategyName
                strategyVersion
            };
        });
    } catch (e) {
        console.error("failed to remap agents:", e);
        return [];
    }
}
export const AgentsOverview = () => {
    const navigate = useNavigate();
    const { featureFlags } = useAppState();
    const { data: agents, isLoading: isLoadingAgents } = useSWR<Agent[]>("live-agents", getLiveAgents);
    console.log("agents", agents);

    const remappedAgents = remapAgents(agents);
    console.log("remappedAgents", remappedAgents);

    const isAgentsEnabled = featureFlags?.[LDKeyAgents];

    const isPnLEnabled = featureFlags?.[LDKeyAgentPnL];
    const { data: pnlData, isLoading: isLoadingPnl } = useSWR(
        isPnLEnabled ? "all-agents-pnl" : null,
        fetchAllAgentsPnl
    );

    if (!isAgentsEnabled) {
        return <PageContentContainer>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <h2>Agents feature is coming soon!</h2>
            </Box>
        </PageContentContainer>
    }

    const handleDeployAgent = () => {
        navigate("/agents/deploy");
    };

    return <PageContentContainer>
        <Flex direction="column" gap="6">
            <Flex justify="between" align="center">
                <h2>Agents</h2>
                <Button onClick={handleDeployAgent}>Create Agent</Button>
            </Flex>
            <AgentsDashboard />
            {isLoadingAgents ? (
                <Flex direction="column" align="center" gap="3" py="8">
                    <Text size="3" weight="medium" color="gray">
                        Loading Agents
                    </Text>
                    <Spinner size="2" />
                </Flex>
            ) : (
                <Table.Root>
                    <Table.Header>
                        <Table.Row>
                            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                            {isPnLEnabled && (
                                <>
                                    <Table.ColumnHeaderCell>APY (24h)</Table.ColumnHeaderCell>
                                    <Table.ColumnHeaderCell>PnL (USD)</Table.ColumnHeaderCell>
                                </>
                            )}
                            <Table.ColumnHeaderCell>Strategy</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Chain</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Wallet</Table.ColumnHeaderCell>
                            <Table.ColumnHeaderCell>Created At</Table.ColumnHeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isLoadingAgents || isLoadingPnl ? (
                            <Table.Row>
                                <Table.Cell colSpan={8}>Loading agents...</Table.Cell>
                            </Table.Row>
                        ) : remappedAgents && remappedAgents.length > 0 ? (
                            remappedAgents.map((agent: any) => {
                                const agentPnl = pnlData?.data?.agents?.find((a: any) => a.id === agent.id);
                                const apy = agentPnl?.apy24h || 0;
                                const pnl = agentPnl?.pnlInUsd || 0;

                                return (
                                    <Table.Row key={agent.id} onClick={() => navigate(`/agents/${agent.id}`)}
                                        style={{ cursor: 'pointer' }}>
                                        <Table.Cell>{agent.name || 'Unnamed Agent'}</Table.Cell>
                                        <Table.Cell>
                                            <AgentStatus status={agent.status} />
                                        </Table.Cell>
                                        {isPnLEnabled && (
                                            <>
                                                <Table.Cell>
                                                    {apy === 0 ? (
                                                        <Text>-</Text>
                                                    ) : (
                                                        <Text color={apy > 0 ? 'green' : 'red'}>
                                                            {apy.toFixed(2)}%
                                                        </Text>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {pnl === 0 ? (
                                                        <Text>-</Text>
                                                    ) : (
                                                        <Text color={pnl > 0 ? 'green' : 'red'}>
                                                            {pnl < 0 ? '-' : ''}${formatMonetaryValue(Math.abs(pnl), { maxFractionDigits: 2 })}
                                                        </Text>
                                                    )}
                                                </Table.Cell>
                                            </>
                                        )}
                                        <Table.Cell>{agent?.strategyName} ({agent?.strategy_version})</Table.Cell>
                                        <Table.Cell><ChainBadge chainId={agent.chainId} /></Table.Cell>
                                        <Table.Cell>{agent.safeAccountName}</Table.Cell>
                                        <Table.Cell>{new Date(agent?.created_at).toLocaleDateString()}</Table.Cell>
                                    </Table.Row>
                                );
                            })
                        ) : (
                            <Table.Row>
                                <Table.Cell colSpan={8}>No agents found</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table.Root>
            )}
        </Flex>
    </PageContentContainer>
}
