import { useState, useEffect } from "react";
import { PersonIcon } from "@radix-ui/react-icons";
import { Box } from "@mui/material";
import { Card, Button, Text, Badge } from "@radix-ui/themes";
import { useAppState } from "../../state/AppStateProvider";
import useSWR from "swr";
import { getUser, updateProfilePicture, updateUser, updateUsername } from "../../api/hasura";
import { Tooltip, IconButton, CircularProgress } from "@mui/material";
import { Info } from "@mui/icons-material";

export default function PublicProfile() {
    const { user, setSnackBar, setUser } = useAppState();

    const [isLoading, setIsLoading] = useState(false);

    const { data: userDetails, mutate: mutateUserDetails } = useSWR(
        user?.userPlatformId ? `user-details` : null,
        () => getUser(user?.id!)
    );

    // Initialize form data with user details when available
    const [formData, setFormData] = useState({
        username: userDetails?.username || "",
        bio: userDetails?.bio || "",
        profilePicture: null as File | null,
    });

    useEffect(() => {
        if (userDetails) {
            setFormData((prev) => ({
                ...prev,
                username: userDetails.username || "",
                bio: userDetails.bio || "",
            }));
        }
    }, [userDetails]);

    const [emailForm, setEmailForm] = useState({
        notification_email: user?.notification_email ?? "",
    });

    const [isUploadingPicture, setIsUploadingPicture] = useState(false);
    const [imageUrl, setImageUrl] = useState(userDetails?.profile_url || "");

    // Update imageUrl when userDetails changes
    useEffect(() => {
        if (userDetails?.profile_url) {
            setImageUrl(userDetails.profile_url);
        }
    }, [userDetails?.profile_url]);

    const handleProfilePictureUpload = async (file: File) => {
        setIsUploadingPicture(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            try {
                const result = await updateProfilePicture(reader.result as string);
                if (result.valid) {
                    // Update image URL immediately with the returned URL
                    if (result.url) {
                        setImageUrl(result.url);
                    }
                    setSnackBar({
                        open: true,
                        message: 'Profile picture updated successfully',
                        severity: 'success'
                    });
                    // Refresh user details in the background
                    mutateUserDetails();
                }
            } catch (error: any) {
                setSnackBar({
                    open: true,
                    message: error.message || 'Failed to update profile picture',
                    severity: 'error'
                });
            } finally {
                setIsUploadingPicture(false);
            }
        };
    };

    const handleSave = async () => {
        if (!user?.id) return;
    
        try {
            setIsLoading(true);
            
            // Create an array of promises for changes that need to be saved
            const updatePromises = [];
            
            // Handle username update if changed
            if (formData.username !== userDetails?.username) {
                updatePromises.push(
                    updateUsername(formData.username)
                        .then(result => {
                            if (!result.valid) {
                                throw new Error(result.message);
                            }
                        })
                );
            }
    
            // Handle bio update if changed
            if (formData.bio !== userDetails?.bio) {
                updatePromises.push(
                    updateUser(user.id, {
                        bio: formData.bio,
                    })
                );
            }
    
            // Execute all updates
            if (updatePromises.length > 0) {
                await Promise.all(updatePromises);
                
                setSnackBar({
                    open: true,
                    message: "Profile updated successfully",
                    severity: "success",
                });
                mutateUserDetails();
            }
        } catch (error: any) {
            setSnackBar({
                open: true,
                message: error.message || "Failed to update profile",
                severity: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleEmailUpdate = async () => {
        if (!user?.id) return;

        try {
            setIsLoading(true);
            await updateUser(user.id, {
                notification_email: emailForm.notification_email,
            });

            setUser({
                ...user,
                notification_email: emailForm.notification_email,
            });

            setSnackBar({
                open: true,
                message: "Email updated successfully",
                severity: "success",
            });
        } catch (error: any) {
            setSnackBar({
                open: true,
                message: error.message || "Failed to update email",
                severity: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Common input styles we can use for both input and textarea
    const inputStyles = {
        width: "100%",
        padding: "12px",
        borderRadius: "8px",
        border: "1px solid var(--gray-6)",
        fontSize: "14px",
        fontFamily: "inherit", // This ensures consistent font family
    };

    return (
        <Box sx={{ maxWidth: "740px", width: "100%" }}>
            <Box sx={{ my: 2, display: "flex", justifyContent: "space-between" }}>
                <Text size="5" weight="bold">Public Profile</Text>
            </Box>

            {/* Profile Section */}
            <Card style={{ padding: '24px', marginBottom: '16px' }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                    {/* Profile Picture and Bio Section */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 4,
                        }}
                    >
                        {/* Profile Picture */}
                        <Box>
                            <Box sx={{
                                width: 200,
                                height: 200,
                                borderRadius: '50%',
                                backgroundColor: 'var(--gray-4)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                overflow: 'hidden',
                                cursor: isUploadingPicture ? 'wait' : 'pointer',
                                margin: '0 auto',
                                '&:hover': {
                                    '& .overlay': {
                                        opacity: 1
                                    }
                                }
                            }}
                                onClick={() => !isUploadingPicture && document.getElementById('profile-picture-input')?.click()}
                            >
                                {isUploadingPicture ? (
                                    <CircularProgress />
                                ) : (
                                    <>
                                        {imageUrl ? (
                                            <img
                                                src={imageUrl}
                                                alt="Profile"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover'
                                                }}
                                                onError={() => {
                                                    setSnackBar({
                                                        open: true,
                                                        message: 'Failed to load profile picture',
                                                        severity: 'error'
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <PersonIcon width={80} height={80} />
                                        )}

                                        <Box className="overlay" sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            opacity: 0,
                                            transition: 'opacity 0.2s ease-in-out'
                                        }}>
                                            <Text style={{
                                                color: 'white',
                                                fontSize: '14px',
                                                fontWeight: '500'
                                            }}>
                                                Change Picture
                                            </Text>
                                        </Box>
                                    </>
                                )}
                            </Box>
                            <input
                                id="profile-picture-input"
                                type="file"
                                hidden
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    if (file) handleProfilePictureUpload(file);
                                }}
                            />
                        </Box>
                        {/* Bio Section */}
                        <Box sx={{
                            width: "100%",
                            maxWidth: "600px",
                            display: "flex",
                            flexDirection: "column",
                            gap: 3
                        }}>
                            {/* Username field */}
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Text size="2" weight="bold">
                                    Username
                                </Text>
                                <input
                                    type="text"
                                    placeholder="Enter your username"
                                    value={formData.username}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            username: e.target.value,
                                        }))
                                    }
                                    style={inputStyles}
                                />
                            </Box>

                            {/* Bio field */}
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <Text size="2" weight="bold">
                                    Bio
                                </Text>
                                <textarea
                                    placeholder="Write a short bio about yourself"
                                    value={formData.bio}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            bio: e.target.value,
                                        }))
                                    }
                                    style={{
                                        ...inputStyles,
                                        minHeight: "150px",
                                        resize: "vertical",
                                        lineHeight: "1.5",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                    {/* Save Button */}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button size="2" onClick={handleSave} loading={isLoading}>
                            Save Changes
                        </Button>
                    </Box>
                </Box>
            </Card>

            {/* Notification Settings Section */}
            <Card style={{ padding: '24px' }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                    <Text size="2" weight="bold">
                        Notification Settings
                    </Text>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            maxWidth: "400px",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Text size="2">Notification Email</Text>
                            <Tooltip title="You will receive notifications when your simulations finish to this e-mail address">
                                <IconButton size="small">
                                    <Info sx={{ width: 16, height: 16 }} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <input
                            type="email"
                            placeholder="Enter notification email"
                            value={emailForm.notification_email}
                            onChange={(e) =>
                                setEmailForm({
                                    notification_email: e.target.value,
                                })
                            }
                            style={inputStyles}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            size="2"
                            onClick={handleEmailUpdate}
                            loading={isLoading}
                            disabled={
                                emailForm.notification_email === user?.notification_email
                            }
                        >
                            Save Email
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
}
