import React, { useState } from "react";
import {
    DropdownMenu,
    Flex,
    Button,
    TextField,
} from "@radix-ui/themes";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    ChevronDownIcon,
    MagnifyingGlassIcon,
    EnterFullScreenIcon,
    ExitFullScreenIcon
} from "@radix-ui/react-icons";

interface LogsFilterProps {
    onFilterChange: (filter: string, severity: "INFO" | "ERROR" | "") => void;
    toTop?: () => void;
    toBottom?: () => void;
    toggleFullscreen?: () => void;
    isFullscreen?: boolean;
}



const LogsFilter: React.FC<LogsFilterProps> = ({ onFilterChange, toTop, toBottom, toggleFullscreen, isFullscreen }) => {
    const [filterText, setFilterText] = useState<string>("");
    const [severity, setSeverity] = useState<"INFO" | "ERROR" | "">("");

    const handleFilterChange = (text: string, severityLevel: "INFO" | "ERROR" | "") => {
        onFilterChange(text, severityLevel);
    };

    return (
        <Flex
            direction="row"
            align="center"
            gap="3"
            width={"100%"}
            justify={"end"}
            my={"2"}
            className="fixed top-2 right-2 bg-white shadow-lg p-3 rounded-lg z-50"
        >
            {/* Log Filter Input */}
            <TextField.Root className={"w-100"} placeholder="Search by text..." value={filterText}
                            onChange={(e) => {
                                setFilterText(e.target.value);
                                handleFilterChange(e.target.value, severity);
                            }}>
                <TextField.Slot>
                    <MagnifyingGlassIcon/>
                </TextField.Slot>
            </TextField.Root>


            {toTop && <Button onClick={toTop} variant={"outline"}>
                <ArrowUpIcon />
            </Button>}

            {toBottom && <Button onClick={toBottom} variant={"outline"}>
                <ArrowDownIcon />
            </Button>}

            {toggleFullscreen && <Button onClick={toggleFullscreen} variant={"outline"}>
                {isFullscreen ? <>Exit Fullscreen <ExitFullScreenIcon/></> : <EnterFullScreenIcon />}
            </Button>}

            {/* Severity Dropdown */}
            <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                    <Button variant="soft" className="flex items-center gap-2">
                        {!!severity ? severity : "All severity"} <ChevronDownIcon />
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="end">
                    <DropdownMenu.Item onSelect={() => { setSeverity(""); handleFilterChange(filterText, ""); }}>
                        All severity
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onSelect={() => { setSeverity("INFO"); handleFilterChange(filterText, "INFO"); }}>
                        Info
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onSelect={() => { setSeverity("ERROR"); handleFilterChange(filterText, "ERROR"); }}>
                        Error
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        </Flex>
    );
};

export default LogsFilter;
