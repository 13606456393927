import { NavigateFunction, Location } from "react-router-dom";

export interface NavigationState {
    backStack?: string[];
}

export function popBackStack(
    navigate: NavigateFunction,
    location: Location & { state?: NavigationState }
): void {
    const stack = location.state?.backStack;
    if (stack && stack.length > 0) {
        const newStack = [...stack];
        const previous = newStack.pop()!;
        navigate(previous, { state: { backStack: newStack } });
    } else {
        // If no custom backStack exists, fall back to browser history.
        navigate(-1);
    }
}

export function pushCurrentRoute(
    location: Location & { state?: NavigationState }
): string[] {
    // Get the current backStack (or start with an empty array)
    const stack = location.state?.backStack ? [...location.state.backStack] : [];
    // Only push if the current route isn’t already the top entry
    if (stack[stack.length - 1] !== location.pathname) {
        stack.push(location.pathname);
    }
    return stack;
}