import { Outlet } from 'react-router-dom';
import SettingsSidebar from '../../components/layout/SettingsSidebar';
import { PageContentContainer } from "../styled/styled";

export default function Settings() {
    return (
        <>
            <SettingsSidebar />
            <PageContentContainer>
                <Outlet />
            </PageContentContainer>
        </>
    );
}