import {Alert, Box, Button, styled, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {useAppState} from "../../../../state/AppStateProvider";
import * as React from "react";
import {useEffect, useState} from "react";
import {getGroupSimulation} from "../../../../api/hasura";
import {useNavigate, useParams} from "react-router-dom";
import {SimulationResultsCards} from "./SimulationResultsCards";
import {DistributionOfReturnsView} from "./DistributionOfReturnsView";
import {FullScreenContainer} from "../../create-simulation/setup-simulation/SetupSimulation";
import {SimulationProgressView} from "../SimulationProgressView";
import {PageLoadingView} from "../../create-simulation/PageLoadingView";
import {ExpandableContent} from "../../../../components/ExpandableContent";
import {createHistograms} from "../../../../utils/histogramUtils";
import {AgentInputParameters} from "../../create-simulation/setup-simulation/summary/AgentInputParameters";
import {ArrowBackIosNew} from "@mui/icons-material";
import {TimeSeriesResultsChart} from "./TimeSeriesResultsChart";
import {ESimulationStatus} from "../../../../state/types";
import {PriceTrajectoriesUsedChart} from "./PriceTrajectoriesUsedChart";

const SimulationContentContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: auto;
    margin-top: 48px;
    margin-bottom: 48px;
`;

//TODO: type
const calculateAvailableDataTypes = (selectedAgentResults: any) => {

    const availableDataTypes: string[] = [];

    try {
        if (selectedAgentResults?.simulation_array?.[0]?.environment_step?.[0]?.ROI !== undefined) {
            availableDataTypes.push("ROI");
        }
        if (selectedAgentResults?.simulation_array?.[0]?.environment_step?.[0]?.PNL !== undefined) {
            availableDataTypes.push("PNL");
        }
        if (selectedAgentResults?.simulation_array?.[0]?.environment_step?.[0]?.ImpermanentLoss !== undefined) {
            availableDataTypes.push("ImpermanentLoss");
        }
        if (selectedAgentResults?.simulation_array?.[0]?.environment_step?.[0]?.IL !== undefined) {
            availableDataTypes.push("IL");
        }
        if (selectedAgentResults?.simulation_array?.[0]?.environment_step?.[0]?.FEES !== undefined) {
            availableDataTypes.push("FEES");
        }
    } catch (err: any) {
        return availableDataTypes;
    }

    return availableDataTypes;
};
const AgentResultsView = () => {

    let {id, agentId} = useParams();
    const navigate = useNavigate();

    const {selectedSimulation, setSelectedSimulation, isLeftSideExpanded} = useAppState();

    const [isFetching, setIsFetching] = useState(false);


    const agentFrontendState = selectedSimulation?.agent_configs?.[0].agents?.find((agent: any) => agent.address === agentId);
    const selectedAgentResults = {
        ...selectedSimulation?.aggregated_metrics?.agents?.[agentId!],
        ...agentFrontendState,
    };

    const dataTypeOptions = calculateAvailableDataTypes(selectedAgentResults);
    const [selectedDataType, setSelectedDataType] = React.useState<string>("ROI");


    const histogramDatas = createHistograms(selectedAgentResults?.simulation_array, dataTypeOptions);

    //console.log("histogramDatas", histogramDatas);

    const fetchSimulation = async (id: string, interval?: any) => {
        setIsFetching(true);
        const simulation = await getGroupSimulation(id);
        setSelectedSimulation(simulation)
        if (simulation.status === ESimulationStatus.Completed || simulation.status === ESimulationStatus.Failed) {
            clearInterval(interval)
        }
        setIsFetching(false);
    }

    useEffect(() => {
        if (id && selectedSimulation && selectedSimulation?.status !== ESimulationStatus.Completed) {
            const interval = setInterval(() => {
                fetchSimulation(id!, interval)
            }, 2000);
            return () => clearInterval(interval);
        }
        if (!isFetching && !selectedSimulation) {
            fetchSimulation(id!)
        }
    }, [id, isFetching, selectedSimulation])

    const isLoading = (!selectedSimulation || selectedSimulation.id !== id);

    const isSimulationCompleted = selectedSimulation?.status === ESimulationStatus.Completed;


    const onSelectedDataTypeSwitch = (e: any, v: any) => {
        setSelectedDataType(e.target.value);
    }

    const CompletedSimulation = () => <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>

        {selectedAgentResults &&
            <SimulationResultsCards results={selectedAgentResults?.computed_statistic}
                                    settings={selectedAgentResults.settings}/>}

        {dataTypeOptions.length !== 0 ? <Box>
                <Box sx={{my: 2}}>
                    <ToggleButtonGroup
                        size={"small"}
                        value={selectedDataType}
                        exclusive
                        onChange={(e: any, value: any) => {
                            onSelectedDataTypeSwitch(e, value);
                        }}
                        aria-label="selected range"
                    >
                        {dataTypeOptions.map(type => <ToggleButton
                            sx={{minWidth: "120px", textTransform: "none"}}
                            selected={type === selectedDataType}
                            key={type}
                            value={type}
                            aria-label={type}>
                            {type}
                        </ToggleButton>)
                        }
                    </ToggleButtonGroup>
                </Box>

                {histogramDatas?.[selectedDataType] &&
                    <DistributionOfReturnsView type={selectedDataType}
                                               results={histogramDatas?.[selectedDataType]?.histogramData}/>}
                <TimeSeriesResultsChart type={selectedDataType}
                                        selectedAgentResults={selectedAgentResults}/>
            </Box> :
            <Box sx={{my: 2}}>
                <Alert variant="filled" severity="info">This agent has either not performed any actions within the
                    simulation, or has not
                    logged any data which we currently support. If you think this is a mistake, please contact
                    us!</Alert></Box>}

        {selectedSimulation?.frontend_state?.priceTrajectoryResults && <PriceTrajectoriesUsedChart/>}
    </Box>;

    return (
        <FullScreenContainer sx={{py: 1}}>
            {isLoading && <PageLoadingView fullscreen title="Loading Agent Simulation results..." subTitle={agentId}/>}
            {!isLoading && selectedSimulation?.aggregated_metrics &&
                <Box>
                    <ExpandableContent title={"Agent Details"}>
                        <AgentInputParameters selectedAgent={selectedAgentResults} sx={{width: "296px"}}/>
                    </ExpandableContent>


                    <SimulationContentContainer sx={isLeftSideExpanded ? {paddingLeft: "350px"} : {}}>
                        <Box>
                            <Button variant="contained"
                                    onClick={() => navigate(-1)}
                                    startIcon={<ArrowBackIosNew/>}>Back</Button>
                        </Box>
                        {selectedSimulation &&
                            <Box sx={{my: 2, display: "flex", justifyContent: "space-between"}}>
                                <Box sx={{display: "flex", flexDirection: 'column'}}>
                                    <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                                <span style={{
                                    fontWeight: "bold",
                                    fontSize: "24px"
                                }}>Agent: {selectedAgentResults?.alias ? `${selectedAgentResults.alias} - ` : ""} {selectedAgentResults.address?.substr(0, 8)}...</span>
                                    </Box>
                                    <span
                                        style={{fontSize: "12px"}}>Wallet address: {selectedAgentResults.address}</span>
                                </Box>
                            </Box>}


                        {isSimulationCompleted ?
                            <CompletedSimulation/> :
                            <SimulationProgressView simulation={selectedSimulation!}/>
                        }

                    </SimulationContentContainer>
                </Box>}
        </FullScreenContainer>
    );
}

export default AgentResultsView;
