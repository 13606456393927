import React, {useState} from "react";
import {Callout, Card, Flex} from "@radix-ui/themes";
import useSWR from "swr";
import {getAgentFiles, getLiveAgentById} from "../../../api/hasura";
import {Agent} from "../AgentDetails";
import "react-folder-tree/dist/style.css";
import FileFolderSidebar from "./FileFolderSiderbar";
import AgentFileEditor from "./AgentFileEditor";
import {ExclamationTriangleIcon} from "@radix-ui/react-icons";

export type FileData = {
    fileName: string;
    metadata: any;
    path: string;
}

const AgentFilesReader = ({agentId}: { agentId?: string }) => {


    const [selectedFile, setSelectedFile] = useState<FileData | null>(null);


    const {
        data: agentDetails,
        isLoading,
        mutate: mutateAgent,
    } = useSWR<Agent>(agentId ? `agents/${agentId}` : null, () =>
        getLiveAgentById(agentId as string)
    );


    const {
        data: agentFiles,
        mutate: mutateAgentFiles,
        isLoading: isLoadingFiles,
        error: filesError
    } = useSWR(agentId ? `agents/${agentId}/files` : null, () => getAgentFiles(agentId as string));


    console.log("agentFiles", agentFiles);


    const onFileSelect = (file: FileData) => {
        console.log("selected file", file);

        setSelectedFile(file);
    }
    return <Flex gap={"1"} direction={"column"}>

        {filesError &&
            <Callout.Root color="red">
                <Callout.Icon>
                    <ExclamationTriangleIcon/>
                </Callout.Icon>
                <Callout.Text>
                    {filesError.toString()}
                </Callout.Text>
            </Callout.Root>
        }

        <Flex gap={"1"} direction={"row"}>

            {agentFiles && <FileFolderSidebar fileData={agentFiles} onFileSelect={onFileSelect}/>}

            {agentId && selectedFile && <AgentFileEditor agentId={agentId} file={selectedFile} onUpdate={() => {
                mutateAgentFiles(null)
                setSelectedFile(null)
            }}/>}

        </Flex>
    </Flex>

}

export default AgentFilesReader;
