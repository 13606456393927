import {FileItem} from "./FileFolderSiderbar";
import {Flex, Text, Button, Callout} from "@radix-ui/themes";
import React, {useMemo} from "react";
import CodeMirror from "@uiw/react-codemirror";
import {useAppState} from "../../../state/AppStateProvider";
import useSWR from "swr";
import {getAgentFileUrl, getLiveAgentById, getSafeWalletIdByAddress} from "../../../api/hasura";
import {json} from "@codemirror/lang-json";
import {ExclamationTriangleIcon, Pencil1Icon} from "@radix-ui/react-icons";
import axios from "axios";
import {Agent} from "../AgentDetails";
import {grantZodiacPermissions} from "../../../utils/zodiacUtils";

const AgentFileEditor = ({agentId, file, onUpdate}: { agentId: string, file: FileItem, onUpdate?: () => void }) => {

    const {theme, setSnackBar, provider} = useAppState();

    const [isEditing, setIsEditing] = React.useState(false);
    const [editedFileContents, setEditedFileContents] = React.useState("");
    const [isApplyingPermissions, setIsApplyingPermissions] = React.useState(false);

    const [isJsonFile, setIsJsonFile] = React.useState(false);

    const {
        data: agentDetails,
    } = useSWR<Agent>(agentId ? `agents/${agentId}` : null, () =>
        getLiveAgentById(agentId as string)
    );

    const walletAddressUsedByAgent =
        agentDetails?.config?.agent_config?.ALMANAK_PLATFORM_WALLETS?.[0]
            ?.SAFE_ACCOUNT_ADDRESS;

    const { data: safeWallet } = useSWR(
        walletAddressUsedByAgent
            ? `safe-wallet-${walletAddressUsedByAgent}-id`
            : null,
        () => getSafeWalletIdByAddress(walletAddressUsedByAgent)
    );

    const safeWalletChainId = safeWallet?.chain_id;


    const {
        data: fileReadUrl,
        mutate: mutateFileReadUrl
    } = useSWR(file ? `agents/${agentId}/${file.path}/read` : null, () => getAgentFileUrl(agentId, file));


    const {data: fileEditUrl} = useSWR(file ? `agents/${agentId}/${file.path}/write` : null, () => getAgentFileUrl(agentId, file, "write"));

    const {
        data: fileContents,
        mutate: mutateFileContents
    } = useSWR(fileReadUrl, () => fetch(fileReadUrl).then((res) => res.text()));



    console.log("fileContents", fileContents);

    const onSaveFileChanges = async () => {
        if (!fileEditUrl) {
            setSnackBar({message: "No signed URL available for editing. Is agent paused?", severity: "error", open: true})
            console.error("No signed URL available for editing.");
            return;
        }

        try {
            console.log("will save", editedFileContents);

            await axios.put(fileEditUrl, editedFileContents, {
                headers: {
                    'Content-Type': isJsonFile ? 'application/json' : "text/plain",
                },
            });


            await mutateFileContents(editedFileContents);
            onUpdate?.();
            setIsEditing(false);
            setSnackBar({message: "File Updated", severity: "success", open: true})


        } catch (error: any) {
            console.error("Error saving file:", error);
            setSnackBar({message: error?.message ?? "Error saving file.", severity: "error", open: true})
        }
    };

    const formattedJson = useMemo(() => {
        if (fileContents) {
            try {
                const parsed = JSON.stringify(JSON.parse(fileContents), null, 2);
                setIsJsonFile(true);
                return parsed
            } catch (e: any) {
                console.log("not JSON", e);
                setIsJsonFile(false);
                return fileContents;
            }
        }
        return "";
    }, [fileContents]);


    const onEdit = () => {
        setIsEditing(true);
        if (fileContents) {
            try {
                const formatted = JSON.stringify(JSON.parse(fileContents), null, 2) // Pretty format with 2 spaces
                setIsJsonFile(true);
                setEditedFileContents(formatted);
            } catch (e: any) {
                console.log("not JSON", e);
                setIsJsonFile(false);
                setEditedFileContents(fileContents);
            }
        }

    }

    const applyPermissionsOnChain = async () => {
        console.log("Applying permissions on chain");
        if (!fileContents) {
            setSnackBar({message: "No file contents available", severity: "error", open: true});
            return;
        }

        setIsApplyingPermissions(true);
        try {
            const res = await grantZodiacPermissions(walletAddressUsedByAgent, provider, safeWalletChainId!, JSON.parse(fileContents));

            console.log("res from grantZodiacPermissions", res);
            if (!res.status) {
                setSnackBar({message: res.message, severity: "info", open: true});
                return;
            } else {
                setSnackBar({message: "Permissions applied on chain", severity: "success", open: true});
            }
        } catch (e: any) {
            setSnackBar({message: e?.message ?? "Error applying permissions on chain", severity: "error", open: true});
        } finally {
            setIsApplyingPermissions(false);
        }
    }

    const isPermissionsFile = file.path.includes("permissions.json");

    return (
        <Flex direction={"column"} gap={"1"}>
            {isEditing &&
                <Callout.Root color="red" my={"2"}>
                    <Callout.Icon>
                        <ExclamationTriangleIcon/>
                    </Callout.Icon>
                    <Callout.Text>
                        Editing strategy files directly is a dangerous operation. Please make sure you know what you are
                        doing.
                    </Callout.Text>
                </Callout.Root>
            }

            {!fileEditUrl &&
                <Callout.Root color="blue" my={"2"}>
                    <Callout.Icon>
                        <ExclamationTriangleIcon/>
                    </Callout.Icon>
                    <Callout.Text>
                        To edit this file, the agent must be in paused state.
                    </Callout.Text>
                </Callout.Root>
            }
            <Text weight={"bold"}>{file.path}</Text>

            <Flex gap={"2"} align={"center"}>



                {fileEditUrl && <div>
                    {!isEditing ? <Button variant={"outline"} onClick={onEdit}> <Pencil1Icon /> Edit File</Button> :
                        <Flex gap={"2"} justify={"between"} align={"center"}>
                            <Button onClick={onSaveFileChanges}>Save</Button>
                            <Button variant={"outline"} onClick={() => setIsEditing(false)}>Cancel</Button>
                        </Flex>
                    }
                </div>}


                {!isEditing && isPermissionsFile && <Button loading={isApplyingPermissions} onClick={applyPermissionsOnChain}>Apply On-Chain</Button>}

            </Flex>
            <CodeMirror
                readOnly={!isEditing}
                maxWidth={"60vw"}
                onChange={(value) => {
                    setEditedFileContents(value);
                }}
                value={isEditing ? editedFileContents : formattedJson}
                theme={theme}
                extensions={[json()]}
            />

        </Flex>
    );
}

export default AgentFileEditor;
