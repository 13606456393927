import { Text } from "@radix-ui/themes";

interface AgentStatusProps {
    status: string;
    size?: "1" | "2" | "3";
}

export const AgentStatus = ({ status, size = "2" }: AgentStatusProps) => {
    const getStatusStyles = (status: string) => {
        if (status.startsWith('ERROR')) {
            return {
                backgroundColor: '#991b1b',
                color: '#fee2e2'
            };
        } else if (status === 'PENDING') {
            return {
                backgroundColor: '#fdf6e3', // Light warm cream
                color: '#8b6f00' // Muted gold
            };
        } else if (status === 'IN_USE') {
            return {
                backgroundColor: '#dbeafe',
                color: '#1e40af'
            };
        } else if (status === "DEPLOYING") {
            return {
                backgroundColor: '#dbeafe',
                color: '#1e40af'
            };
        } else if (status === "RUNNING") {
            return {
                backgroundColor: 'var(--accent-a11)',
                color: 'white'
            };
        } else if (status === "TERMINATED") {
            return {
                backgroundColor: '#f3f4f6', // Soft gray
                color: '#374151' // Dark gray
            };
        } else if (status === "NORMAL_PAUSE") {
            return {
                backgroundColor: '#dbeaf1',
                color: '#1e40af'
            };
        }
        return {
            backgroundColor: '#fff3dc',
            color: '#996b00'
        };
    };

    const statusStyles = getStatusStyles(status);


    let displayStatus = status.toLowerCase().replace(/_/g, ' ');


    if (status === "ERROR_PAUSE") {
        displayStatus = "Error";
    }

    if (status === "NORMAL_PAUSE") {
        displayStatus = "Paused";
    }
    return (
        <Text
            size={size}
            style={{
                padding: '4px 8px',
                borderRadius: '4px',
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                ...statusStyles
            }}
        >
            {displayStatus}
        </Text>
    );
};

interface AgentStatusProps {
    status: string;
    size?: "1" | "2" | "3";
}
