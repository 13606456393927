import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LDKeyAgents } from "../../components/layout/Sidebar";
import { useAppState } from "../../state/AppStateProvider";
import { PageContentContainer } from "../styled/styled";
import { Box } from "@mui/material";
import { ethers } from "ethers";
import {
    Flex,
    Text,
    Button,
    Select,
    TextField,
    Callout,
    Spinner,
    Card,
    Tabs,
    Table,
    IconButton,
    Dialog, Badge,
} from "@radix-ui/themes";
import {
  createLiveAgent,
  deployLiveAgent,
  fetchArtifactFilesWithUrl,
  getArtifactLibraryItems,
  getSmartWallets,
  getSupportedChains,
} from "../../api/hasura";
import useSWR from "swr";
import { ChainBadge } from "../wallets/ChainBadge";
import {
  createZodiacPermissioTxnWithPermission,
} from "../wallets/safeFunctions";
import Safe from "@safe-global/protocol-kit";
import {
  ALMANAK_AGENT_ROLE_KEY,
  ALMANAK_AGENT_ROLE_NAME,
} from "../wallets/WalletCreationFlow";
import {
  ArrowLeftIcon,
  ExclamationTriangleIcon,
  OpenInNewWindowIcon,
} from "@radix-ui/react-icons";
import CodeMirror from "@uiw/react-codemirror";

import { json } from "@codemirror/lang-json";
import ErrorBoundary from "../../components/error-handling/ErrorBoundary";
import { SupportedChain } from "../wallets/WalletsOverview";
import {useWallets} from "@privy-io/react-auth";
import {grantZodiacPermissions} from "../../utils/zodiacUtils";

const styles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.textContent = styles;
document.head.appendChild(styleSheet);

const  TransactionWaitingModal = React.memo(
  ({
    txHash,
    choosenChain,
  }: {
    txHash: string;
    choosenChain: SupportedChain | null;
  }) => (
    <Dialog.Root open={true}>
      <Dialog.Content
        style={{
          maxWidth: 450,
          animation: "fadeIn 150ms ease-out",
        }}
      >
        <Flex direction="column" gap="3">
          <Dialog.Title>Transaction Pending </Dialog.Title>
            <Flex direction="row" gap="1" align={"center"}>
                <Spinner size="1" />
                <Text size="3" weight={"medium"}>Loading...</Text>
            </Flex>
          <Flex gap="3" align="center">



            <Flex direction="column" gap="2">
              <Text size={"3"}>
                Waiting for transaction to be included in the block.
              </Text>
              <Flex direction="column" gap="2">
                <Text size="2" color="gray">
                  Transaction Hash:
                </Text>




                <Text size="2" style={{ wordBreak: "break-all" }}>
                  {txHash}
                </Text>
                <div>
                    <IconButton
                        size="1"
                        variant="ghost"
                        onClick={() =>
                          window.open(
                            `${choosenChain?.block_explorer_url}/tx/${txHash}`,
                            "_blank"
                          )
                        }
                >
                        Open in Explorer
                  <OpenInNewWindowIcon />
                </IconButton>
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
);


const availableArbitrumConfigurations = [
    "MAGIC-WETH",
    "PENDLE-WETH",
    "WETH-ARB",
    "WETH-USDC",
    "XAI-WETH"
];

const availableBaseConfigurations = [
    "AIXBT-USDC",
    "WETH-AIXBT",
    "WETH-USDC"
];


const availableConfigurations = {
    "8453": availableBaseConfigurations, // Base Chain ID
    "42161": availableArbitrumConfigurations, // Arbitrum Chain ID
}

export const AgentDeployment = () => {
  const navigate = useNavigate();
  const { theme, featureFlags, provider, setSnackBar } = useAppState();
  const {wallets} = useWallets();

  const isAgentsEnabled = featureFlags?.[LDKeyAgents];

  const [agentName, setAgentName] = useState("");
  const [selectedStrategy, setSelectedStrategy] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");
  const [isDeploying, setIsDeploying] = useState(false);
  const [selectedStrategyVersion, setSelectedStrategyVersion] = useState("");
  const [availableConfigurationsForSelectedWallet, setAvailableConfigurationsForSelectedWallet] = useState<string[] | null>(null);
  const [selectedPool, setSelectedPool] = useState("");
  const [agentConfigError, setAgentConfigError] = useState<string | null>(null);

  const [customPermissions, setCustomPermissions] = useState(null);

  const [selectedTab, setSelectedTab] = useState<"permissions" | "config">(
    "permissions"
  );

    console.log("WalletCreationFlow wallets", wallets);

    const connectedWallet = wallets[0];

  const { data: artifactList, isLoading: isLoadingArtifacts } = useSWR(
    "strategy-templates",
    getArtifactLibraryItems
  );


  const artifactVersions = useMemo(() => {
    const artifact = artifactList?.find(
      (artifact: any) => artifact.id === selectedStrategy
    );
    return artifact?.artifact_versions ?? [];
  }, [selectedStrategy, artifactList]);


  const latestArtifactVersion = useMemo(() => {
    const artifact = artifactList?.find(
      (artifact: any) => artifact.id === selectedStrategy
    );
    return artifact?.latest_version_artifact;
  }, [selectedStrategy, artifactList]);

  const selectedArtifactVersion = useMemo(() => {
    const artifact = artifactList?.find(
      (artifact: any) => artifact.id === selectedStrategy
    );
    const latestVersion =
      artifact?.latest_version_artifact ?? artifact?.artifact_versions?.[0];



    if (selectedStrategyVersion) {
        const selectedVersion = artifact?.artifact_versions?.find(
            (version: any) => version.id === selectedStrategyVersion
        );
        return {
            ...selectedVersion,
            version: selectedVersion?.name,
            artifactName: artifact?.name,
            uri: selectedVersion?.uri,
        };
    }


    return {
      ...latestVersion,
      version: latestVersion?.name,
      artifactName: artifact?.name,
      uri: latestVersion?.uri,
    };
  }, [selectedStrategy, artifactList, selectedStrategyVersion]);

  console.log("selectedArtifactVersion", selectedArtifactVersion);

  const { data: artifactFilesWithUrl } = useSWR(
    selectedArtifactVersion ? `${selectedArtifactVersion?.id}-files` : null,
    () =>
      fetchArtifactFilesWithUrl(
        selectedArtifactVersion?.artifactName,
        selectedArtifactVersion?.version
      )
  );

  console.log("artifactFilesWithUrl", artifactFilesWithUrl);
  const configFileUrl = useMemo(() => {
    if (
      !selectedArtifactVersion?.metadata?.config_file_path ||
      !artifactFilesWithUrl
    ) {
      console.log("Missing required data:", {
        configPath: selectedArtifactVersion?.metadata?.config_file_path,
        hasArtifactFiles: !!artifactFilesWithUrl,
      });
      return null;
    }

    const relativeConfigFilePath =
      selectedArtifactVersion.metadata.config_file_path.replace(/^\//, "");
    console.log("Looking for config file:", {
      configPath: relativeConfigFilePath,
      availablePaths: artifactFilesWithUrl.map((f: { relative_path: any; }) => f.relative_path),
    });

    const configFile = artifactFilesWithUrl.find((file: any) =>
      file.relative_path.endsWith(relativeConfigFilePath)
    );

    return configFile?.presigned_url;
  }, [artifactFilesWithUrl, selectedArtifactVersion]);

  const { data: configFileContents, isLoading: configFileContentsLoading } = useSWR(configFileUrl, async () => {
    const res = await fetch(configFileUrl);
    return res.json();
  });

  const [agentConfiguration, setAgentConfiguration] = useState<any>(configFileContents);

  const {
    data: walletList,
    isLoading: isLoadingWallets,
    mutate: mutateWallets,
    isValidating: isWalletsValidating,
  } = useSWR(`READY-user-wallets`, () => getSmartWallets("ALL", "READY"));

  const selectedWalletDetails = useMemo(() => {
    return walletList?.find((wallet: any) => wallet.id === selectedWallet);
  }, [selectedWallet, walletList]);

  const isAccessControlVisible = useMemo(() => {
    console.log("isAccessControlVisible selectedWallet", selectedWallet);
    console.log("isAccessControlVisible selectedStrategy", selectedStrategy);

    const ret = selectedWallet !== "" && selectedStrategy !== "" && selectedPool !== "";
    console.log("ret", ret);
    return ret;
  }, [selectedWallet, selectedStrategy, selectedPool]);

  const isAgentReadyToDeploy = useMemo(() => {
    return selectedWallet !== "" && selectedStrategy !== "" && agentName !== "" && selectedPool !== "";
  }, [selectedWallet, selectedStrategy, agentName, selectedPool]);

  const hasNoWallets = useMemo(() => {
    return !isLoadingWallets && (!walletList || walletList.length === 0);
  }, [isLoadingWallets, walletList]);

  const [isWaitingForTx, setIsWaitingForTx] = useState(false);
  const [currentTxHash, setCurrentTxHash] = useState<string>("");

  const { data: supportedChains } = useSWR(
    "supported-chains",
    getSupportedChains,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 3600000, // Cache for 1 hour (in milliseconds)
      // or
      staleTime: 3600000, // Consider data fresh for 1 hour
    }
  );
  const [choosenChain, setChoosenChain] = useState<SupportedChain | null>(null);

  const permissionsRequiredByStrategy = selectedArtifactVersion?.metadata?.safe_wallets_permission;
  const usedPermissions = customPermissions || permissionsRequiredByStrategy;


  if (!isAgentsEnabled) {
    return (
      <PageContentContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h2>Agents feature is coming soon!</h2>
        </Box>
      </PageContentContainer>
    );
  }

  const handleDeploy = async () => {

    try {
        console.log("handleDeploy deploy agent! agent name:", agentName);
        console.log("handleDeploy Deploying agent:", {
            agentName,
            strategy: selectedStrategy,
            selectedWallet,
        });

        console.log("handleDeploy selectedStrategy", selectedStrategy);
        console.log(
            "handleDeploy selectedArtifactVersion",
            selectedArtifactVersion
        );

        const config = {
            agent_config: {
                ALMANAK_STRATEGY_PERMISSIONS: usedPermissions,
                ALMANAK_STRATEGY_CONFIG: agentConfiguration,
                ALMANAK_PLATFORM_WALLETS: [
                    {
                        CHAIN_ID: selectedWalletDetails?.chain_id,
                        CHAIN_TYPE: "EVM",
                        EOA_ADDRESS:
                        selectedWalletDetails?.external_own_accounts?.[0].address,
                        SAFE_ACCOUNT_NAME: selectedWalletDetails.name,
                        SAFE_ACCOUNT_ADDRESS: selectedWalletDetails?.address,
                    },
                ],
                ALMANAK_STRATEGY_ENV: {}
            },
            strategy_gcs_location: selectedArtifactVersion.uri,
        };

        console.log("config", config);
        const strategyVersion = selectedArtifactVersion?.version;
        const agent = await createLiveAgent(
            agentName,
            selectedArtifactVersion?.id,
            config,
            strategyVersion
        );

        const deployedAgent = await deployLiveAgent(agent.id);

        console.log("handleDeploy deployedAgent", deployedAgent);
        setSnackBar({
            open: true,
            message: "Agent Deploying",
            severity: "success",
        });
        console.log("agent", agent);
        // After successful deployment, navigate back to the overview
        navigate(`/agents/${agent?.id}`);
    } catch (error: any) {
        throw new Error("Could not deploy agent. " + error?.message || "");
    }
  };

  // const enableAccessForAlmanakAgent = async () => {
  //
  //
  //     const protocolKit = await Safe.init({provider: provider, safeAddress: walletDetails.address});
  //
  //     const rolesModTrx = await getDeployRolesModuleTrx();
  //     const expectedZodiacModuleAddress = rolesModTrx[1].to;
  //
  //     const applyRoleTrx = await getApplyRoleTRX(expectedZodiacModuleAddress, ALMANAK_AGENT_ROLE_NAME)
  //
  //     const transactions = [...rolesModTrx, ...applyRoleTrx]
  //
  //     const safeTransaction = await protocolKit.createTransaction({transactions: transactions})
  //     const txResponse = await protocolKit.executeTransaction(safeTransaction)
  //
  //     if (txResponse.transactionResponse) {
  //         const transactionResponse = txResponse.transactionResponse as TransactionResponse;
  //         await transactionResponse.wait(); // Call wait() on transactionResponse
  //         console.log('Zodiac Roles modifier module is deployed and enabled');
  //         console.log('Contract address: ', expectedZodiacModuleAddress);
  //     } else {
  //         console.error('transactionResponse is undefined.');
  //     }
  // };


  const onPermissionsChange = (value: string) => {
      try {
          // Parse the string value back to an object since we need it as an object later
          const parsedPermissions = JSON.parse(value);
          setCustomPermissions(parsedPermissions);
      } catch (e) {
          // Optionally handle invalid JSON
          console.error("Invalid Permissions:", e);
          setSnackBar({
                open: true,
                message: "Invalid Permissions",
                severity: "error",
          });
      }
  }

  const onConfigChange = (value: string) => {
    try {
      setAgentConfigError(null);
      // Parse the string value back to an object since we need it as an object later
      const parsedConfig = JSON.parse(value);
      setAgentConfiguration(parsedConfig);
    } catch (e: any) {
      // Optionally handle invalid JSON
      console.error("Invalid JSON configuration:", e);
      setAgentConfigError(e.message);
    }
  };


  const submitAgent = async () => {
    console.log("submitAgent selectedWallet", selectedWallet);
    console.log("selectedWalletDetails", selectedWalletDetails);
    console.log("submitAgent selectedStrategy", selectedStrategy);
    console.log("submitAgent configFileContents", configFileContents);


    if (agentConfigError) {
        setSnackBar({
            open: true,
            message: "Invalid JSON configuration: " + agentConfigError,
            severity: "error",
        });
        return;
    }
    //get the chain id from the selectedWalletDetails
    const chainId = selectedWalletDetails?.chain_id;
    console.log("submitAgent chainId", chainId);
    //get the rpc url from the supportedChains
    const selectedChain = supportedChains?.find(
      (chain: any) => chain.chain_id === chainId
    );
    const rpcUrl = selectedChain?.rpc_url;

    console.log("submitAgent selectedChain", selectedChain);
    setChoosenChain(selectedChain || null); // Handle undefined case by setting to null
    setIsDeploying(true);
    try {

      await connectedWallet?.switchChain(Number(chainId));

      const txResponse = await grantZodiacPermissions(selectedWalletDetails?.address, provider, selectedWalletDetails?.chain_id, usedPermissions); // this deploy and grant permissions to the agent role

      if (txResponse.error === false && txResponse.status === true) {
        const txHash = txResponse.txResponse?.hash;
        //create a ethereum provider to call RPC to check if the tx is included in the block
        //TODO: allow context awareness on which chain we're on and use the correct RPC
        const rpcProvider = new ethers.providers.JsonRpcProvider(rpcUrl);
        if (!txHash) {
          throw new Error("No transaction created when granting permissions.");
        }
        //wait for the tx to be included in the block
        const txReceipt = await waitForTransaction(txHash, rpcProvider);
        console.log("Transaction confirmed:", {
          blockNumber: txReceipt.blockNumber,
          hash: txReceipt.transactionHash,
        });
      }

      //deploy agent
      await handleDeploy();
    } catch (e: any) {
      console.error("submitAgent error", e);
      setSnackBar({
        open: true,
        message: e.message,
        severity: "error",
      });
    } finally {
      setIsDeploying(false);
    }
  };
  const waitForTransaction = async (
    txHash: string,
    provider: ethers.providers.Provider,
    timeoutSeconds = 300
  ) => {
    setCurrentTxHash(txHash);
    setIsWaitingForTx(true);

    try {
      const startTime = Date.now();
      for (let i = 0; i < timeoutSeconds; i++) {
        const txReceipt = await provider.getTransactionReceipt(txHash);
        if (txReceipt?.status === 1) {
          // Ensure modal stays visible for at least 3 seconds
          const elapsedTime = Date.now() - startTime;
          if (elapsedTime < 3000) {
            await new Promise((resolve) =>
              setTimeout(resolve, 3000 - elapsedTime)
            );
          }
          return txReceipt;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      throw new Error("Transaction confirmation timeout");
    } finally {
      setIsWaitingForTx(false);
      setCurrentTxHash("");
    }
  };

  const onStrategySelect = (value: string) => {
      console.log("onStrategySelect value", value);
      setSelectedStrategy(value);

      const artifact = artifactList?.find((artifact: any) => artifact.id === value);
      setSelectedStrategyVersion(artifact?.latest_version_artifact?.id);
  }

  const onVersionSelect = (value: string) => {
      console.log("onVersionSelect value", value);

      setSelectedStrategyVersion(value);
  }

  const onSelectWallet = (value: string) => {
      setSelectedWallet(value);

      console.log("onSelectWallet value", value);

      const wallet = walletList?.find((wallet: any) => wallet.id === value);
      console.log("onSelectWallet wallet", wallet);

      const chainId = wallet.chain_id as keyof typeof availableConfigurations;

      const availableConfigurationsForChain = availableConfigurations[chainId];

      console.log("availableConfigurationsForChain", availableConfigurationsForChain);

      setAvailableConfigurationsForSelectedWallet(availableConfigurationsForChain);
  }

  const onPoolSelect = async (value: string) => {
      setSelectedPool(value);

      try {
          const wallet = walletList?.find((wallet: any) => selectedWallet === wallet.id);

          const chainId = wallet.chain_id as keyof typeof availableConfigurations;


          const configFilePath = `/deployment-files/${chainId}/config-${value}.json`;
          const permissionsFilePath = `/deployment-files/${chainId}/permissions-${value}.json`;

          // Fetch the file contents
          const configurationFileResponse = await fetch(configFilePath);
          const permissionsFileResponse = await fetch(permissionsFilePath);

          // Parse the JSON content
          const configurationFileContent = await configurationFileResponse.json();
          const permissionsFileContent = await permissionsFileResponse.json();

          console.log('onPoolSelect configurationFileContent:', configurationFileContent);
          console.log('onPoolSelect permissionsFileContent:', permissionsFileContent);


          setCustomPermissions(permissionsFileContent);
          setAgentConfiguration(configurationFileContent);

      } catch (error) {
          console.error('Error fetching file contents:', error);
      }

  }

  return (
    <PageContentContainer>
      {isWaitingForTx && (
        <TransactionWaitingModal
          txHash={currentTxHash}
          choosenChain={choosenChain}
        />
      )}

      <Flex direction="column" gap="6" align={"center"}>
        <Flex justify="start" gap={"2"} align="center" width={"100%"}>
          <Button variant={"outline"} onClick={() => navigate("/agents")}>
            <ArrowLeftIcon />
            Back
          </Button>
          <Text size="6" weight="bold">
            Deploy New Agent
          </Text>
        </Flex>
        <Flex
          direction="column"
          gap="4"
          style={{ width: "100%", maxWidth: "800px" }}
        >
          <Flex direction="column" gap={"1"}>
            <Text size="2" weight="bold">Agent Name</Text>
            <TextField.Root
              placeholder="Enter Agent name"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
            >
            </TextField.Root>
          </Flex>


          <Flex direction="column" gap={"1"}>
            <Text size="2" weight="bold">Select Strategy</Text>
            <Select.Root
              value={selectedStrategy}
              onValueChange={onStrategySelect}
            >
              <Select.Trigger placeholder="Select a strategy" />
              <Select.Content>
                {isLoadingArtifacts ? (
                  <Select.Item value="loading">Loading...</Select.Item>
                ) : (
                  artifactList?.map((artifact: any) => (
                    <Select.Item key={artifact.id} value={artifact.id}>
                      {artifact.name}
                    </Select.Item>
                  ))
                )}
              </Select.Content>
            </Select.Root>
          </Flex>

          {selectedStrategy && <Flex direction="column" gap={"1"}>
              <Text size="2" weight="bold">Select Strategy Version</Text>
              <Select.Root
                  disabled={isLoadingArtifacts}
                  value={selectedArtifactVersion?.id}
                  onValueChange={onVersionSelect}
              >
                  <Select.Trigger placeholder="Select a version" />
                  <Select.Content>

                      {artifactVersions?.map((artifact: any) => (
                          <Select.Item key={artifact.id} value={artifact.id}>
                              {artifact.name} {artifact.id === latestArtifactVersion?.id ? <Badge mx={"1"} color={"green"}>Latest</Badge> : null}
                          </Select.Item>
                      ))}
                  </Select.Content>
              </Select.Root>
          </Flex>}


          {selectedStrategy && <Flex direction="column" gap={"1"}>
            <Text size="2" weight="bold">Select Wallet to be Used</Text>

              {hasNoWallets && (
                  <Callout.Root color="red">
                      <Callout.Icon>
                          <ExclamationTriangleIcon />
                      </Callout.Icon>
                      <Callout.Text>
                          No wallets ready to deploy! Please create and setup wallet
                          before deploying an agent.
                      </Callout.Text>
                  </Callout.Root>
              )}

            <Select.Root value={selectedWallet} onValueChange={onSelectWallet}>
            <Select.Trigger placeholder="Select a wallet" />
            <Select.Content>
              {isLoadingWallets ? (
                <Select.Item value="loading">Loading...</Select.Item>
              ) : (
                walletList?.map((wallet: any) => (
                  <Select.Item key={wallet.id} value={wallet.id}>
                    <Flex width="100%" align="center" gap="2">
                      {wallet.name}
                      <ChainBadge chainId={wallet.chain_id} />
                    </Flex>
                  </Select.Item>
                ))
              )}
            </Select.Content>
          </Select.Root>
          </Flex>}

          {selectedWallet && <Flex direction="column" gap={"1"}>
              <Text size="2" weight="bold">Select Pool</Text>
              <Select.Root
                  value={selectedPool}
                  onValueChange={onPoolSelect}
              >
                  <Select.Trigger placeholder="Select a pool" />
                  <Select.Content>
                      {isLoadingArtifacts ? (
                          <Select.Item value="loading">Loading...</Select.Item>
                      ) : (
                          availableConfigurationsForSelectedWallet?.map((configuration: any) => (
                              <Select.Item key={configuration} value={configuration}>
                                  {configuration}
                              </Select.Item>
                          ))
                      )}
                  </Select.Content>
              </Select.Root>
          </Flex>}

            {isAccessControlVisible && (
            <Tabs.Root
              defaultValue="permissions"
              value={selectedTab}
              onValueChange={(value: string) =>
                setSelectedTab(value as "permissions" | "config")
              }
            >
              <Tabs.List>
                <Tabs.Trigger value="permissions">
                  Strategy Permissions
                </Tabs.Trigger>
                <Tabs.Trigger value="config">
                  Strategy Configuration
                </Tabs.Trigger>
              </Tabs.List>

              <Box style={{ marginTop: "16px" }}>
                <Tabs.Content value="permissions">

                {selectedArtifactVersion && !selectedArtifactVersion?.metadata
                    ?.safe_wallets_permission && (
                    <Callout.Root color="red">
                        <Callout.Icon>
                            <ExclamationTriangleIcon />
                        </Callout.Icon>
                        <Callout.Text>
                            The strategy has no permissions defined! The deployment might fail.
                        </Callout.Text>
                    </Callout.Root>
                )}


                  {(customPermissions || selectedArtifactVersion?.metadata
                    ?.safe_wallets_permission) && (
                    <ErrorBoundary>
                      <CodeMirror
                          onChange={onPermissionsChange}
                          value={JSON.stringify(
                          customPermissions ?? selectedArtifactVersion?.metadata
                            ?.safe_wallets_permission,
                          null,
                          2
                        )}
                        theme={theme}
                        extensions={[json()]}
                      />
                    </ErrorBoundary>
                  )}
                </Tabs.Content>

                <Tabs.Content value="config">

                    {!configFileContentsLoading && !configFileContents && (
                        <Callout.Root color="red">
                            <Callout.Icon>
                                <ExclamationTriangleIcon />
                            </Callout.Icon>
                            <Callout.Text>
                                The strategy has no configuration file, or the file is empty! The deployment might fail.
                            </Callout.Text>
                        </Callout.Root>
                    )}

                  {(agentConfiguration || configFileContents) && (
                    <CodeMirror
                      readOnly={false}
                      onChange={onConfigChange}
                      value={JSON.stringify(agentConfiguration ?? configFileContents, null, 2)}
                      theme={theme}
                      extensions={[json()]}
                    />
                  )}
                </Tabs.Content>
              </Box>
            </Tabs.Root>
          )}

          <Button
            disabled={!isAgentReadyToDeploy || isDeploying}
            onClick={submitAgent}
          >
            {isAgentReadyToDeploy && "Grant Permissons and "}Deploy Agent
            {isDeploying && <Spinner />}
          </Button>

          {/*<Button*/}
          {/*    onClick={handleDeploy}*/}
          {/*    disabled={hasNoWallets || !isAgentReadyToDeploy}*/}
          {/*>*/}
          {/*    Deploy Agent*/}
          {/*</Button>*/}
        </Flex>
      </Flex>
    </PageContentContainer>
  );
};
