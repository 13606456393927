import {Navigate, Route, Routes} from "react-router-dom";
import "./App.css";
import SimulationHistory from "./views/simulation-history/SimulationHistory";
import PoolDetails from "./views/simulation/create-simulation/PoolDetails";
import {Alert, Snackbar, ThemeProvider} from "@mui/material";
import {lightTheme, darkTheme} from "./styles/themes";
import Onboarding from "./views/onboarding/Onboarding";
import ProtectedRoutes from "./router/ProtectedRoutes";
import {Main} from "./components/layout/styled";
import * as React from "react";
import AuthorizationManager from "./auth/AuthorizationManager";
import ErrorBoundary from "./components/error-handling/ErrorBoundary";
import SetupSimulation from "./views/simulation/create-simulation/setup-simulation/SetupSimulation";
import {useAppState} from "./state/AppStateProvider";
import AgentResultsView from "./views/simulation/results/agent-results/AgentResultsView";
import {AlphaCountdown} from "./components/AlphaCountdown";
import {AgentOverview} from "./views/agent/AgentOverview";
import {PriceSimulatorDirect} from "./views/price-sim-direct/PriceSimulatorDirect";
import {StrategyLibrary} from "./views/strategy/StrategyLibrary";
import SelectUseCase from "./views/demo/SelectUseCase";
import {CreateSimulation} from "./views/demo/CreateSimulation";
import SimulationResults from "./views/simulation/results/SimulationResults";
import UserProfile from "./views/profile/UserProfile";
import {PriceSimulationWidget} from "./widgets/PriceSimulationWidget";
import {StrategyLibraryWidget} from "./widgets/StrategyLibraryWidget";
import {StrategyLibraryDetailsWidget} from "./widgets/StrategyLibraryDetailsWidget";
import {FeatureStoreWidget} from "./widgets/FeatureStoreWidget";
import WhitelistManager from "./auth/WhitelistManager";
import {Dashboard} from "./views/dashboard/Dashboard";
import {BuyCredits} from "./views/buy-credits/BuyCredits";
import {SuccessPurchase} from "./views/buy-credits/SuccessPurchase";
import {FailedPurchase} from "./views/buy-credits/FailedPurchase";
import {WalletsOverview} from "./views/wallets/WalletsOverview";
import {WalletDetails} from "./views/wallets/WalletDetails";
import WalletCreationFlow from "./views/wallets/WalletCreationFlow";
import {AgentsOverview} from "./views/agents/AgentsOverview";
import {PrivyProvider} from "@privy-io/react-auth";
import {AgentDeployment} from "./views/agents/AgentDeployment";
import {Theme} from "@radix-ui/themes";
import {AgentDetails} from "./views/agents/AgentDetails";
import Settings from "./views/settings/Settings";
import ApiKeys from "./views/settings/ApiKeys";
import PublicProfile from "./views/settings/PublicProfile";
import {UserPoints} from "./views/user-points/UserPoints";


const routes = <Routes>
    <Route path="/onboarding" element={<Onboarding/>}/>
    <Route element={<ProtectedRoutes/>}>
        <Route path="/home" element={<Dashboard/>}/>
        <Route path="/demo" element={<CreateSimulation/>}/>
        <Route path="/user/:walletAddress/profile" element={<UserProfile/>}/>
        <Route path="/buy-credits" element={<BuyCredits/>}/>
        <Route path="/user-points" element={<UserPoints/>}/>
        <Route path="/buy-credits/success" element={<SuccessPurchase/>}/>
        <Route path="/buy-credits/error" element={<FailedPurchase/>}/>
        <Route path="/select-use-case" element={<SelectUseCase/>}/>
        <Route path="/pools/:id" element={<PoolDetails/>}/>
        <Route path="/simulation/:id" element={<SetupSimulation/>}/>
        <Route path="/simulation/:id/results" element={<SimulationResults/>}/>
        <Route path="/simulation/:id/results/agent/:agentId" element={<AgentResultsView/>}/>
        <Route path="/simulation-history" element={<SimulationHistory/>}/>
        <Route path="/wallets" element={<WalletsOverview/>}/>
        <Route path="/wallets/:id" element={<WalletDetails/>}/>
        <Route path="/wallets/create" element={<WalletCreationFlow/>}/>
        <Route path="/wallets/create/:id" element={<WalletCreationFlow/>}/>
        <Route path="/strategy-library" element={<StrategyLibraryWidget/>}/>
        <Route path="/strategy-library/:artifactId" element={<StrategyLibraryDetailsWidget/>}/>
        <Route path="/price-simulation" element={<PriceSimulationWidget/>}/>
        <Route path="/agents" element={<AgentsOverview/>}/>
        <Route path="/agents/:id" element={<AgentDetails/>}/>
        <Route path="/agents/deploy" element={<AgentDeployment/>}/>
        <Route path="/feature-store" element={<FeatureStoreWidget/>}/>
        <Route path="/simulation/:simulationId/agent/:id" element={<AgentOverview/>}/>
        <Route path="/simulation/:simulationId/agent/:id/strategies" element={<StrategyLibrary/>}/>
        <Route path="/countdown" element={<AlphaCountdown/>}/>
        <Route path="/price-sim-direct" element={<PriceSimulatorDirect/>}/>
        <Route path="/settings" element={<Settings />}>
            <Route path="profile" element={<PublicProfile />} />
            <Route path="api-keys" element={<ApiKeys />} />
            <Route index element={<Navigate to="/settings/profile" replace />} />
        </Route>
    </Route>
    <Route path='*' element={<Navigate to='/home'/>}/>
</Routes>;

function App() {

    const {theme} = useAppState();
    const themePallete = theme === "dark" ? darkTheme : lightTheme;

    const {snackBar, setSnackBar} = useAppState();
    return <ThemeProvider theme={themePallete}>
        <Theme appearance={theme}>
            <ErrorBoundary>
                <PrivyProvider appId={process.env.REACT_APP_PRIVY_APP_ID ?? "cm1eqzipt0cep54b8v8h32tov"}>
                    <AuthorizationManager/>

                    <WhitelistManager>
                        <Main>
                            {routes}
                        </Main>
                    </WhitelistManager>
                </PrivyProvider>
            </ErrorBoundary>
            {snackBar && <Snackbar open={snackBar.open} autoHideDuration={6000} onClose={() => setSnackBar(null)}
                                   anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
                <Alert variant="filled" onClose={() => setSnackBar(null)} severity={snackBar.severity}
                       sx={{width: '100%'}}>
                    {snackBar.message}
                </Alert>
            </Snackbar>}
        </Theme>

    </ThemeProvider>


}

export default App;
