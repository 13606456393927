import {LibraryArtifact} from "../../widgets/StrategyLibraryWidget";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import {CenteredRowFlexBox, PanelBox} from "../simulation/create-simulation/styled";
import {formatDateValue} from "../simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";
import {Badge, Button, Card, Flex, Text} from "@radix-ui/themes";
import {useNavigate} from "react-router-dom";
import {ChainBadge} from "../wallets/ChainBadge";
import { starStrategy, unstarStrategy } from "../../api/hasura";
import { StarIcon, StarFilledIcon } from '@radix-ui/react-icons';
import { useSWRConfig } from 'swr';
import { useAppState } from "../../state/AppStateProvider";


export const ArtifactItem = ({artifact, isDetails}: { artifact: LibraryArtifact, isDetails?: boolean }) => {

    const navigate = useNavigate();
    const { user, setSnackBar} = useAppState();
    const { mutate } = useSWRConfig();
    const [isStarring, setIsStarring] = useState(false);

    const isStarred = artifact?.is_star_by_user ?? artifact?.artifact_stars?.some(star => star.user_id === user?.id);

    const handleStarToggle = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (isStarring) return;

        setIsStarring(true);
        try {
            if (isStarred) {
                await unstarStrategy(artifact.id);
            } else {
                await starStrategy(artifact.id);
            }
            // setSnackBar({open: true, message: isStarred ? "Unstarred" : "Starred", severity: "success"});

        } catch (error: any) {
            setSnackBar({open: true, message: error?.message ?? "An error occured", severity: "error"});

            console.error('Error toggling star:', error);
        } finally {
            await mutate('strategy-templates');
            await mutate('public-artifacts');
            await mutate(`artifacts-${artifact.author}`);
            setIsStarring(false);
        }
    };

    const openArtifactDetails = () => {
        //console.log("item", artifact);
        navigate(`/strategy-library/${artifact.id}`)
    };

    return <Box sx={{borderBottom: "1px solid var(--gray-6)"}}>
        <Box sx={{p: 2}}>
            <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                <Button variant="ghost" onClick={openArtifactDetails}>
                    <span className={"title"}>{artifact.name}</span>
                </Button>

                <Badge style={{marginLeft: "8px"}} color={artifact?.is_public ? "green" : "gray"} radius={"full"} size={"1"}>
                    {artifact.is_public ? "Public" : "Private"}
                </Badge>

                {/*<span>{formatDateValue(artifact.date_created)}</span>*/}
            </Box>

            {artifact.description?.length > 0 && <Box>
                <span style={{fontSize: "14px"}}>{artifact.description}</span>
            </Box>}


            {artifact.author_user && <Box>
                <CenteredRowFlexBox sx={{gap: "16px"}}>
                    {artifact.author_user.name && <span style={{fontSize: "14px"}}>{artifact.author_user.name}</span>}
                    {artifact.author_user.email && <span style={{fontSize: "14px"}}>{artifact.author_user.email}</span>}
                </CenteredRowFlexBox>
            </Box>}


            <Flex direction={"row"} align={"center"} gap={"2"} style={{marginTop: "16px"}}>
                {artifact.latest_version_artifact?.metadata?.chain_id && <ChainBadge chainId={artifact.latest_version_artifact.metadata.chain_id}></ChainBadge>}
                {/*<ChainBadge chainId={"42161"}></ChainBadge>*/}
                {artifact.latest_version_artifact?.name && <Badge size={"3"}>
                    {artifact.latest_version_artifact?.name}
                </Badge>}

                <Text size={"2"}>
                    Created {formatDateValue(artifact.date_created)}
                </Text>
                <Button
                    variant="ghost"
                    loading={isStarring}
                    onClick={handleStarToggle}
                    style={{ marginLeft: "auto" }}
                >
                    <Flex align="center" gap="1">

                        {isStarred ? <StarFilledIcon color="var(--blue-9)" /> : <StarIcon color="var(--gray-9)" />}
                        <Text size="2" color={isStarred ? "blue" : "gray"}>
                            {artifact.stars_count || 0}
                        </Text>
                    </Flex>
                </Button>
            </Flex>

            {/*<Box sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>*/}
            {/*    <Box>*/}
            {/*        {artifact.metadata?.tags && <Box>*/}
            {/*            <div style={{padding: "16px 0 8px 0", fontSize: "14px", fontWeight: "bold"}}>Tags</div>*/}
            {/*            <CenteredRowFlexBox sx={{gap: "8px"}}>*/}
            {/*                {artifact.metadata?.tags.map((tag: string) => <Badge>{tag}</Badge>)}*/}
            {/*            </CenteredRowFlexBox>*/}
            {/*        </Box>}*/}
            {/*    </Box>*/}
            {/*    /!*{!isDetails && <Button onClick={openArtifactDetails}>Show Details</Button>}*!/*/}
            {/*</Box>*/}
        </Box>


    </Box>
}
