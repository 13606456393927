import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import {Link, matchRoutes, useLocation, useNavigate} from "react-router-dom";
import UserDropdown from "../wallet-connect/UserDropdown";
import Box from "@mui/material/Box";
import {LDKeyGlobalMaintenanceMode, LDKeyUserApiKeyPanel, useAppState} from "../../state/AppStateProvider";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {
    CenteredColumnFlexBox,
    CenteredRowFlexBox,
} from "../../views/simulation/create-simulation/styled";
import {Alert, CircularProgress, styled} from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import {Button, Callout, IconButton} from "@radix-ui/themes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {AlmanakLogo} from "../AlmanakLogo";
import {formatDateValue, formatMonetaryValue} from "../../views/simulation/create-simulation/PoolDetails";
import {fullScreenRoutes} from "./Sidebar";
import {EditOutlined} from "@mui/icons-material";
import {updateGroupSimulation, getUserCreditBalance, getUserPointsBalance} from "../../api/hasura";
import {SimulationTitleModal} from "../../modals/SimulationTitleModal";
import {useState, useEffect} from "react";
import useSWR from "swr";
import {ExclamationTriangleIcon, MoonIcon, StarFilledIcon, StarIcon, SunIcon} from "@radix-ui/react-icons";

const ThemedToolbar = styled(Toolbar)(({theme}) => ({
    width: "100%",
    height: "48px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    zIndex: 1200
}));

const BackButton = () => {
    const {selectedSimulation, setSelectedSimulation, setSnackBar} =
        useAppState();


    const location = useLocation();
    const navigate = useNavigate();

    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const onEditSimulationDescription = () => {
        setIsEditingTitle(true);
    };

    const onSaveTitle = async (title: string) => {
        try {
            setIsSaving(true);
            const simulation = await updateGroupSimulation(selectedSimulation?.id!, {
                frontend_state: {
                    ...selectedSimulation?.frontend_state,
                    title,
                },
            });
            setSelectedSimulation(simulation);
            setSnackBar({open: true, message: "Simulation renamed", severity: "success"});

        } catch (error: any) {
            setSnackBar({open: true, message: error.message, severity: "error"});
        } finally {
            setIsSaving(false);
            setIsEditingTitle(false);
        }
    };

    return (
        <Box sx={{display: "flex", alignItems: "center"}}>
            <IconButton
                onClick={() => {
                    navigate("/simulation-history")
                }}
            >
                <ArrowBackIcon/>
            </IconButton>
            {selectedSimulation && (
                <CenteredColumnFlexBox sx={{mx: 2}}>
                    <div>
            <span style={{fontSize: "14px", fontWeight: 500}}>
              {selectedSimulation?.frontend_state?.title}
            </span>
                        {isSaving ? (
                            <CircularProgress sx={{marginLeft: "8px"}} size={12}/>
                        ) : (
                            <IconButton onClick={onEditSimulationDescription}>
                                <EditOutlined sx={{width: "16px", height: "16px"}}/>
                            </IconButton>
                        )}
                    </div>
                    <div style={{display: "flex", fontSize: "12px", fontWeight: 500}}>
            <span
                style={{
                    color: "#6B7280",
                    paddingRight: "4px",
                }}
            >
              Date created:{" "}
            </span>
                        <span>{formatDateValue(selectedSimulation?.created_at, true)}</span>
                        <span style={{padding: "0 4px 0 8px", color: "#6B7280"}}>
              SID:{" "}
            </span>
                        <span>{selectedSimulation?.id}</span>
                    </div>
                </CenteredColumnFlexBox>
            )}
            {isEditingTitle && (
                <SimulationTitleModal
                    isOpen={isEditingTitle}
                    onClose={() => setIsEditingTitle(false)}
                    onSave={onSaveTitle}
                    title={selectedSimulation?.frontend_state?.title ?? ""}
                />
            )}
        </Box>
    );
};


export default function Header() {
    const {
        theme,
        changeTheme,
        reloadCreditBalance,
        setReloadCreditBalance,
        user,
        featureFlags
    } = useAppState();

    const isGlobalMaintenanceMode = featureFlags?.[LDKeyGlobalMaintenanceMode];


    const location = useLocation();
    const matchedRoute = matchRoutes(
        fullScreenRoutes.map((r) => {
            return {path: r};
        }),
        location
    )?.[0];
    const isSimulationRoute = matchedRoute?.route.path.includes("simulation/:id");
    // const [creditBalance, setCreditBalance] = useState('loading');


    const {
        data: creditBalance,
        isLoading: isCreditBalanceLoading,
        mutate: mutateCreditBalance,
    } = useSWR(user ? `user-credits` : null, getUserCreditBalance);

    const {
        data: userPoints,
        isLoading: isUserPointsLoading,
    } = useSWR(user ? "user-points-balance" : null, () => getUserPointsBalance(user?.id));



    console.log("userPoints", userPoints);

    // const loadUserCreditBalance = async () => {
    //     const creditBalance = await getUserCreditBalance();
    //     setCreditBalance(creditBalance.data.data.getCreditBalance.credit_balance);
    // };

    // useEffect(() => {
    //     if (user !== null) {
    //         if (reloadCreditBalance) {
    //             loadUserCreditBalance();
    //             setReloadCreditBalance(false);
    //         }
    //     } else {
    //         setCreditBalance('loading');
    //     }
    // }, [user, reloadCreditBalance, setReloadCreditBalance]);
    return (
        <ThemedToolbar>

            <Box>
                {isSimulationRoute ? <BackButton/> :
                    isGlobalMaintenanceMode === true ?
                        <Box style={{paddingLeft: user !== null ? "220px" : 0}}>
                        <Callout.Root color="red">
                            <Callout.Icon>
                                <ExclamationTriangleIcon/>
                            </Callout.Icon>
                            <Callout.Text>
                                Platform is under maintenence.
                            </Callout.Text>
                        </Callout.Root> </Box>: null
                }
            </Box>


            <CenteredRowFlexBox>
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "text.primary",
                        borderRadius: 1,
                        p: 3,
                        gap: 2,
                    }}
                >
                    {/*{user !== null && user.isWhitelisted && (*/}
                    {/*    <Stack spacing={1} alignItems="center">*/}
                    {/*        <Link to={"/buy-credits"}>*/}
                    {/*            <Button*/}
                    {/*                color={"brown"}*/}
                    {/*                variant={"ghost"}>*/}
                    {/*                {isCreditBalanceLoading ? "Loading..." : formatMonetaryValue(creditBalance) + " credits"}*/}
                    {/*            </Button>*/}
                    {/*        </Link>*/}
                    {/*    </Stack>*/}
                    {/*)}*/}

                    {user !== null && user.isWhitelisted && (
                        <Stack spacing={1} alignItems="center">
                            <Link to={"/user-points"}>
                                <Button
                                    color={"brown"}
                                    variant={"ghost"}>
                                    <StarFilledIcon />
                                    {isUserPointsLoading ? "Loading..." : userPoints + " points"}
                                </Button>
                            </Link>
                        </Stack>
                    )}


                </Box>
                <UserDropdown/>

                <IconButton style={{marginLeft: "16px"}} color={"indigo"} variant={"outline"} onClick={changeTheme}>
                    {theme === "dark" ? <SunIcon/> : <MoonIcon/>}
                </IconButton>
            </CenteredRowFlexBox>
        </ThemedToolbar>
    );
}
