import * as React from "react";
import {useAppState} from "../../state/AppStateProvider";
import {useTheme} from "@mui/material";
import {PageContentContainer} from "../styled/styled";
import useSWR from "swr";
import {getUserPointsBalance} from "../../api/hasura";
import {Button, Flex, Text} from "@radix-ui/themes"
import {TitleValueColumn} from "../../modals/AddWalletModal";
import {OpenInNewWindowIcon} from "@radix-ui/react-icons";
import {openAlmanakDocs} from "../../components/layout/Sidebar";

export const UserPoints = () => {

    const {user} = useAppState();

    const {
        data: userPoints,
        isLoading: isUserPointsLoading,
    } = useSWR(user ? "user-points-balance" : null, () => getUserPointsBalance(user?.id));


    return (
        <PageContentContainer>
            <Flex direction={"column"} gap={"2"} py={"2"}>
                <Text size={"5"} weight={"bold"}>
                    Almanak Platform Points
                </Text>

                <Text>Points are rewarded for using the Almanak Platform.</Text>

                <div><Button variant={"outline"} onClick={() => openAlmanakDocs('docs/getting-started')}>Learn
                    more <OpenInNewWindowIcon/></Button></div>


                <Flex direction={"row"} gap={"4"} py={"4"}>

                    <TitleValueColumn size="16px" title={"Your Points"} value={isUserPointsLoading ? "Loading..." : userPoints}/>
                    {/*<TitleValueColumn size="16px" title={"Rank"} value={"N/A"}/>*/}
                    {/*<TitleValueColumn size="16px" title={"League"} value={"🪵 Wood"}/>*/}
                </Flex>

            </Flex>
        </PageContentContainer>
    );
};
