import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import {CenteredColumnFlexBox} from "../views/simulation/create-simulation/styled";
import {Button, MenuItem, Modal, Select, SelectChangeEvent, TextField} from "@mui/material";
import * as React from "react";
import {AlmanakModalContent} from "./ScenarioCustomizationModal";
import {useAppState} from "../state/AppStateProvider";
import {AddOutlined, DeleteOutline} from "@mui/icons-material";
import {useState} from "react";
import {getNativeAmount, Money} from "../utils/moneyUtils";

export type TokenDetails = {
    token: string;
    address: string;
    isNative: boolean;
    decimalPlaces: number;
    nativeLabel?: string;
}

export type WalletHolding = {
    mint: boolean, token: string, amount: string; displayAmount: string;
}
export type WalletDetails = {
    address: string;
    holdings: Array<WalletHolding>
}
type WalletModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onSave: (walletDetails: WalletDetails) => void;
    data: WalletDetails
};

export const TitleValueColumn = (props: { title: string, value: string | number | any, size?: string }) => {
    return <CenteredColumnFlexBox sx={{paddingRight: 1, py: 1, justifyContent: "space-between"}}>
        <span style={{fontSize: props.size ?? "14px", fontWeight: "bold"}}>{props.title}</span>
        <span style={{fontSize: props.size ?? "14px"}}>{props.value}</span>
    </CenteredColumnFlexBox>
}
export const AddWalletModal = ({isOpen, onClose, onSave, data}: WalletModalProps) => {

    const {availableTokens} = useAppState();
    const [walletDetails, setWalletDetails] = useState(data)
    const [walletAvailableTokens, setWalletAvailableTokens] = useState(availableTokens ?? []);

    const convertedHoldings = walletDetails.holdings.map(h => {
        const token = walletAvailableTokens.find(t => t.address === h.token);
        if (token) {
            const money = new Money(h.amount, token);
            return {...h, displayAmount: money.displayAmount}
        }
        return h;
    });

    const addToken = () => {
        setWalletDetails(prevState => ({
            ...prevState,
            holdings: [...prevState.holdings, {mint: false, token: "", amount: "", displayAmount: ""}]
        }));
    };

    const removeToken = (token: any) => {
        setWalletDetails(prevState => ({
            ...prevState,
            holdings: prevState.holdings.filter(t => t.token !== token)
        }));
        setWalletAvailableTokens(prevState => [...prevState, token]);
    }

    const selectToken = (e: SelectChangeEvent<string>, v: any, holding: WalletHolding) => {
        if (!availableTokens) {
            return;
        }
        const newHoldings = walletDetails.holdings.map(h => {
            if (h === holding) {
                return {...h, token: e.target.value, amount: ""}
            }
            return h;
        });
        setWalletDetails(prevState => ({...prevState, holdings: newHoldings}));
        setWalletAvailableTokens(prevState => prevState.filter(t => t.address !== e.target.value));
    };

    const changeTokenAmount = (e: React.ChangeEvent<HTMLInputElement>, holding: WalletHolding) => {
        const newHoldings = walletDetails.holdings.map(h => {
            if (h === holding) {
                return {...h, displayAmount: e.target.value}
            }
            return h;
        });
        setWalletDetails(prevState => ({...prevState, holdings: newHoldings}));
    }

    const onSaveWallet = () => {
        const remappedHoldingsWithNativeAmounts = walletDetails.holdings.map(h => {
            const token = availableTokens?.find(t => t.token === h.token);
            const nativeAmount = getNativeAmount(h.displayAmount, token?.decimalPlaces ?? 0);
            return {
                token: h.token,
                amount: nativeAmount,
                mint: h.mint,
                displayAmount: h.displayAmount
            }
        });
        const remappedWalletDetails = {...walletDetails, holdings: remappedHoldingsWithNativeAmounts};
        onSave(remappedWalletDetails);
        onClose();
    }

    return <Modal
        sx={{display: "flex"}}
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <AlmanakModalContent>
            <Box sx={{
                p: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold"
            }}>
                Create New Wallet
                <IconButton onClick={onClose}><CloseIcon/></IconButton>
            </Box>
            <Divider/>

            <Box sx={{p: 2}}>
                <TitleValueColumn title={"Wallet Address"} value={walletDetails.address}/>

                <div style={{fontSize: "14px", fontWeight: "bold", paddingTop: "16px"}}>Tokens</div>
                <Box sx={{display: "flex", flexDirection: "column", gap: 1, py: 2}}>
                    {convertedHoldings.map((holding, i) =>
                        <Box sx={{display: "flex", gap: 1}} key={holding.token}>
                            <Select
                                sx={{width: "140px"}}
                                name={holding.token}
                                value={holding.token}
                                onChange={(e, v) => selectToken(e, v, holding)}
                                size={"small"}>
                                {availableTokens?.map(token =>
                                    <MenuItem key={token.address}
                                              disabled={!!walletDetails.holdings.find(h => h.token === token.token)}
                                              value={token.token}>{token.token}</MenuItem>)}
                            </Select>
                            <TextField
                                label={"Amount"}
                                type="number"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => changeTokenAmount(event, holding)}
                                fullWidth size="small" value={holding.displayAmount}/>
                            {walletDetails.holdings.length > 1 && <IconButton onClick={() => removeToken(holding.token)}
                                                                              sx={{borderRadius: "8px", width: "44px"}}
                                                                              size={"small"}>
                                <DeleteOutline sx={{color: "#f44336"}}/>
                            </IconButton>}
                        </Box>)}
                </Box>

                {walletDetails.holdings.length < availableTokens?.length! && <Box sx={{textAlign: "end"}}>
                    <Button onClick={addToken} startIcon={<AddOutlined/>} variant="text">Add Token</Button>
                </Box>}
            </Box>


            <Box sx={{p: 3, display: "flex", justifyContent: "flex-end", gap: 1}}>
                <Button onClick={onClose} variant={"outlined"}>Close</Button>
                <Button onClick={onSaveWallet} variant="contained">Save</Button>
            </Box>
        </AlmanakModalContent>
    </Modal>
}
