import { useMemo } from "react";
import { AppDrawer } from "./styled";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@radix-ui/themes";
import { ArrowLeftIcon, PersonIcon, CodeIcon } from "@radix-ui/react-icons";
import { styled } from '@mui/material';
import { Box } from '@mui/material';
import { AlmanakLogo } from "../AlmanakLogo";
import { popBackStack } from "../../utils/navUtils";

const ThemedLink = styled(Link)(() => ({
    "> button": {
        justifyContent: "flex-start"
    }
}));

const menuItems = [
    { title: "Public Profile", icon: PersonIcon, route: "/settings/profile" },
    { title: "API Keys", icon: CodeIcon, route: "/settings/api-keys" }
];

export default function SettingsSidebar() {
    const location = useLocation();
    const navigate = useNavigate();

    const handleBack = () => {
        popBackStack(navigate, location);
    };

    return (
        <AppDrawer variant="permanent" anchor="left">
            <Box sx={{ margin: "8px" }}></Box>
            <AlmanakLogo size={69} />
            <Box sx={{ margin: "8px" }}></Box>

            <ThemedLink
                to="#"
                onClick={(e) => {
                    e.preventDefault();  // Prevent default link behavior
                    handleBack();
                }}
            >
                <Button
                    variant="ghost"
                    style={{
                        boxShadow: "none",
                        margin: "4px",
                        width: "228px"
                    }}
                    size="3"
                >
                    <ArrowLeftIcon style={{ marginRight: 8 }} />
                    Back
                </Button>
            </ThemedLink>

            <div style={{ margin: "16px 0" }}>
                <h3 style={{ margin: "0 16px" }}>Settings</h3>
            </div>

            {menuItems.map((item) => {
                // If already in settings, use replace so that history isn’t extended
                const useReplace = location.pathname.startsWith("/settings");
                return (
                    <ThemedLink
                        key={item.title}
                        to={item.route}
                        replace={useReplace}
                        // Forward the same state (which includes the backStack)
                        state={location.state}
                    >
                        <Button
                            style={{
                                boxShadow: "none",
                                margin: "4px",
                                width: "228px",
                            }}
                            size="3"
                            variant={location?.pathname?.includes(item.route) ? "soft" : "outline"}
                        >
                            <item.icon style={{ marginRight: 8 }} />
                            {item.title}
                        </Button>
                    </ThemedLink>
                );
            })}
        </AppDrawer>
    );
}