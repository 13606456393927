import {SimulationInputParameters} from "../create-simulation/setup-simulation/summary/SimulationInputParameters";
import {useAppState} from "../../../state/AppStateProvider";
import {ExpandableContent} from "../../../components/ExpandableContent";
import {FullScreenContainer} from "../create-simulation/setup-simulation/SetupSimulation";
import {PageLoadingView} from "../create-simulation/PageLoadingView";
import {useEffect, useState} from "react";
import {SimulationStatus} from "../../simulation-history/SimulationStatus";
import {useParams} from "react-router-dom";
import {
    fetchAggregatedMetricsFromUri,
    getAggregatedMetricsForGroupSimulation, getAvailableTokenPairs, getAvailableTokens,
    getGroupSimulation
} from "../../../api/hasura";
import {SimulationProgressView} from "./SimulationProgressView";
import {Alert, Button, styled} from "@mui/material";
import Box from "@mui/material/Box";
import {AgentsResultsListTable} from "./AgentsResultsListTable";
import {SimulationFailedView} from "./SimulationFailedView";
import {ESimulationStatus} from "../../../state/types";
import {RestartAlt} from "@mui/icons-material";
import {FlexCol} from "../create-simulation/styled";
import * as React from "react";

const SimulationResults = () => {
    const {id} = useParams();
    const {
        selectedSimulation,
        setSelectedSimulation,
        isLeftSideExpanded,
        setSnackBar,
        setAvailableTokens,
        availableTokens,
        availableTokenPairs,
        setAvailableTokenPairs,
    } = useAppState();


    const isSDKSimulation = selectedSimulation?.version === 3;

    const {simulationMetricsUri} = selectedSimulation || {};
    const [isFetchingSimulation, setIsFetchingSimulation] = useState<boolean>(false);
    const [isFetchingMetrics, setIsFetchingMetrics] = useState<boolean>(false);

    const [isSupersetVisible, setIsSupersetVisible] = useState<boolean>(false);

    const [error, setError] = useState(null);


    const openRawMetrics = () => {
        if (simulationMetricsUri) window.open(simulationMetricsUri, "_blank");
    }

    const toggleSupersetView = () => {
        setIsSupersetVisible(prevState => !prevState);
    }
    const fetchSimulation = async (id: string, interval?: any) => {
        setIsFetchingSimulation(true);
        try {
            const simulation = await getGroupSimulation(id);
            setSelectedSimulation(simulation)
            if (simulation.status === ESimulationStatus.Completed || simulation.status === ESimulationStatus.Failed) {
                clearInterval(interval)
            }
        } catch (err: any) {
            console.error(err);
            setSnackBar({severity: "error", message: "Failed to fetch simulation results", open: true});
        } finally {
            setIsFetchingSimulation(false);
        }
    }
    const fetchSimulationMetrics = async (id?: string) => {
        if (!id) {
            return;
        }
        setError(null);
        setIsFetchingMetrics(true);
        try {
            const simulationMetricsUri = await getAggregatedMetricsForGroupSimulation(id);
            const metrics = await fetchAggregatedMetricsFromUri(simulationMetricsUri);
            // @ts-ignore
            setSelectedSimulation(prevState => ({...prevState, aggregated_metrics: metrics, simulationMetricsUri}))

        } catch (err: any) {
            console.error(err);
            setSnackBar({severity: "error", message: "Failed to fetch simulation metrics", open: true});
            setError(err.message)
        } finally {
            setIsFetchingMetrics(false);
        }
    }

    const fetchAvailableTokens = async () => {
        const tokens = await getAvailableTokens()
        setAvailableTokens(tokens);
    }

    useEffect(() => {
        if (id && selectedSimulation && selectedSimulation?.status !== ESimulationStatus.Completed && selectedSimulation?.status !== ESimulationStatus.Failed) {
            const interval = setInterval(() => {
                fetchSimulation(id!, interval)
            }, 5000);
            return () => clearInterval(interval);
        }
        if (!error && !isFetchingSimulation && !selectedSimulation) {
            fetchSimulation(id!)
        }


        if (!isSDKSimulation && !error && !isFetchingMetrics && selectedSimulation && !selectedSimulation.aggregated_metrics && selectedSimulation?.status === ESimulationStatus.Completed) {
            fetchSimulationMetrics(id!);
        }

        if (!availableTokens) {
            fetchAvailableTokens();
        }
    }, [id, isFetchingMetrics, isFetchingSimulation, selectedSimulation, isSDKSimulation])

    const isLoading = (!selectedSimulation || selectedSimulation.id !== id);

    const isSimulationCompleted = selectedSimulation?.status === ESimulationStatus.Completed;
    const isSimulationFailed = selectedSimulation?.status === ESimulationStatus.Failed
    const isSimulationInProgress = selectedSimulation?.status === ESimulationStatus.InProgress || selectedSimulation?.status === ESimulationStatus.InQueue;

    const SimulationContentContainer = styled(Box)`
        display: flex;
        flex-direction: column;
        width: auto;
        margin-top: 48px;
        margin-bottom: 48px;
    `;

    const copyId = async () => {
        if (!selectedSimulation?.id) {
            setSnackBar({open: true, message: "No Simulation ID!", severity: "error"});
            return;
        }
        await navigator.clipboard.writeText(selectedSimulation?.id)
        setSnackBar({open: true, message: "Copied to clipboard"})
    }

    useEffect(() => {
        const fetchAvailableTokenPairs = async () => {
            const res = await getAvailableTokenPairs();
            setAvailableTokenPairs(res)
        };
        if (!availableTokenPairs) {
            fetchAvailableTokenPairs();
        }
    }, [])



    return (
        <FullScreenContainer sx={{py: 1}}>
            {isLoading && <PageLoadingView fullscreen title="Loading Simulation details..." subTitle={id}/>}
            {!isLoading && selectedSimulation?.frontend_state &&
                <Box>
                    <ExpandableContent title={"Simulation parameters"}>
                        <SimulationInputParameters selectedSimulation={selectedSimulation} sx={{width: "296px"}}/>
                    </ExpandableContent>

                    <SimulationContentContainer sx={isLeftSideExpanded ? {paddingLeft: "350px"} : {}}>
                        {selectedSimulation &&
                            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                <Box sx={{display: "flex", flexDirection: 'column'}}>
                                    <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                                        {/*TODO: we should come up with numbering system / UX friendly variable here*/}
                                        <span style={{
                                            fontWeight: "bold",
                                            fontSize: "24px"
                                        }}>Simulation {selectedSimulation.id?.substr(0, 4)}...{selectedSimulation.id?.substr(32)}</span>
                                        <SimulationStatus status={selectedSimulation.status}/>
                                    </Box>
                                    <div><span style={{fontSize: "12px"}}>ID: {selectedSimulation.id}</span> <Button
                                        className={"btn-xs"} onClick={copyId}>Copy</Button></div>

                                    <span style={{fontSize: "12px"}}>Date created {selectedSimulation.created_at}</span>
                                    {selectedSimulation.frontend_state.token0 && <span
                                        style={{fontSize: "12px"}}>Simulated pair {selectedSimulation.frontend_state.token0} - {selectedSimulation.frontend_state.token1}</span>}
                                </Box>
                                {simulationMetricsUri &&
                                    <FlexCol>
                                        <Button onClick={openRawMetrics}>See Aggregated Raw Metrics </Button>
                                        {/*<Button*/}
                                        {/*    onClick={toggleSupersetView}>{isSupersetVisible ? "Hide Superset" : "Show results in Superset"}</Button>*/}
                                    </FlexCol>}
                            </Box>}


                        {!isSDKSimulation && error && <Box sx={{my: 2}}><Alert variant="filled" severity="error"
                                                                               sx={{
                                                                                   display: "flex",
                                                                                   alignItems: "center",
                                                                                   width: "100%"
                                                                               }}>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center"
                            }}>
                                <span>Failed to fetch simulation metrics: {error}</span>
                                <Button startIcon={<RestartAlt/>} onClick={() => fetchSimulationMetrics(id)}
                                        sx={{marginLeft: "auto", color: "#FFF"}} variant="text"
                                        size="small">Retry</Button>
                            </Box>
                        </Alert>
                        </Box>}


                        {isSDKSimulation &&

                            <Box sx={{my: 2, gap: "16px"}}>

                                <Alert variant="filled" severity="info"
                                       sx={{display: "flex", alignItems: "center", width: "100%"}}>
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignItems: "center"
                                    }}>
                                        <span>This simulation has been started from Almanak SDK. You should fetch the metrics from the Almanak SDK.</span>
                                    </Box>
                                </Alert>

                            </Box>}


                        {!isSDKSimulation && isSimulationCompleted &&
                            <>
                                {isSupersetVisible ? <iframe
                                        src="https://dashboards.almanak.co"
                                        style={{border: 'none', width: '100%', height: 'calc(100vh - 300px)'}}
                                        title="Superset Dashboard"
                                    ></iframe> :
                                    <AgentsResultsListTable isLoading={isFetchingSimulation || isFetchingMetrics}/>
                                }

                            </>
                        }
                        {isSimulationInProgress && <SimulationProgressView simulation={selectedSimulation!}/>}

                        {isSimulationFailed && <SimulationFailedView simulation={selectedSimulation}/>}

                    </SimulationContentContainer>
                </Box>}
        </FullScreenContainer>
    );
}

export default SimulationResults;
