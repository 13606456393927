import * as React from "react";
import { Divider } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoadingButton from '@mui/lab/LoadingButton';
import { formatPubKey, toChecksumAddress } from "../../utils/commonUtils";
import { useAppState } from "../../state/AppStateProvider";
import useAuth from "../../hooks/useAuth";
import LogoutIcon from '@mui/icons-material/Logout';
import { AccountCircle } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogin, usePrivy } from "@privy-io/react-auth";
import { Button } from "@radix-ui/themes";
import * as Select from "@radix-ui/react-select";
import {
    ArrowDownIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon, ExitIcon,
    PersonIcon,
    GearIcon
} from "@radix-ui/react-icons";


import "./styles.css";

import { DropdownMenu } from "@radix-ui/themes";
import useSWR from "swr";
import { getUser } from "../../api/hasura";
import { pushCurrentRoute } from "../../utils/navUtils";


export default function UserDropdown() {

    const navigate = useNavigate();
    const location = useLocation();
    const { user, isWalletConnecting, setUser, setIsWalletConnecting, setSnackBar } = useAppState();
    const { initSessionWithPrivy, logout } = useAuth();

    const { user: userFromPrivy, ready } = usePrivy();

    const {
        data: userDetails,
    } = useSWR(user?.userPlatformId ? `user-details` : null, () => getUser(user?.id!));


    console.log("userDetails", userDetails);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { login: loginWithPrivy } = useLogin({
        // @ts-ignore
        onComplete: (user, isNewUser, wasAlreadyAuthenticated, loginMethod, linkedAccount) => {
            console.log("loginWithPrivy on complete", user, isNewUser, wasAlreadyAuthenticated, loginMethod, linkedAccount);

            setIsWalletConnecting(true);

            const idToken = localStorage.getItem("privy:id_token")?.replaceAll("\"", "");


            if (!idToken) {
                console.error("idToken not found in local storage");
                return;
            }

            if (!user.wallet?.address) {
                console.error("wallet address not found in user object");
                return;
            }
            initSessionWithPrivy(idToken, user.wallet?.address);
        },
        onError: (error: any) => {
            console.error("loginWithPrivy error!", error);
            setIsWalletConnecting(false);
            logout();
            // Any logic you'd like to execute after a user exits the login flow or there is an error
        },
    });

    const onWalletButtonClick = (event: any) => {
        if (user?.publicKey) {
            setAnchorEl(event.currentTarget);
        } else {
            loginWithPrivy()
            // login(web3Auth);
        }
    }

    // const loginWithPrivvyFunc = async () => {
    //     const res = await loginWithPrivy();
    //     console.log("res from privy", res);
    //
    //     setSnackBar({open: true, message: "Logged in with Privy", severity: "success"});
    // }


    if (user && !isWalletConnecting && !user?.isWhitelisted) {
        return (
            <DropdownMenu.Item
                onClick={() => {
                    setAnchorEl(null);
                    logout();
                }}
            >
                <LogoutIcon sx={{ marginRight: 1 }} />Log out
            </DropdownMenu.Item>
        );
    }


    if (!user) {
        return <Button variant="soft" onClick={onWalletButtonClick} disabled={!ready} loading={isWalletConnecting}>
            Connect Wallet
        </Button>
    }
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger>
                <Button variant="soft">
                    {userDetails?.main_name_service ?? formatPubKey(user?.publicKey)}
                    <ChevronDownIcon />
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content style={{ minWidth: "144px" }}>
                <DropdownMenu.Item
                    onClick={() => {
                        const ownProfilePath = `/user/${user?.publicKey}/profile`;

                        // If we're already on our own profile, just close the dropdown
                        if (location.pathname.toLowerCase() === ownProfilePath.toLowerCase()) {
                            setAnchorEl(null);
                            return;
                        }

                        // If we're toggling between user profiles or between settings and profile,
                        // use replace navigation to collapse the history
                        if (
                            location.pathname.startsWith("/user/") ||
                            location.pathname.startsWith("/settings")
                        ) {
                            navigate(ownProfilePath, { replace: true });
                        } else {
                            // Otherwise, push the current route into the backStack
                            navigate(ownProfilePath, {
                                state: { backStack: pushCurrentRoute(location) },
                            });
                        }
                    }}
                >
                    <PersonIcon />
                    Profile
                </DropdownMenu.Item>
                <DropdownMenu.Item
                    onClick={() => {
                        const settingsPath = "/settings/profile";

                        // If we're already in settings, do nothing
                        if (location.pathname === settingsPath) {
                            setAnchorEl(null);
                            return;
                        }

                        // If toggling between a user profile and settings or within settings,
                        // use replace so that we don’t accumulate history entries
                        if (
                            location.pathname.startsWith("/user/") ||
                            location.pathname.startsWith("/settings")
                        ) {
                            navigate(settingsPath, { replace: true });
                        } else {
                            navigate(settingsPath, {
                                state: { backStack: pushCurrentRoute(location) },
                            });
                        }
                    }}
                >
                    <GearIcon />
                    Settings
                </DropdownMenu.Item>
                <DropdownMenu.Separator />
                <DropdownMenu.Item color="red" onClick={() => logout()}>
                    <ExitIcon />
                    Logout
                </DropdownMenu.Item>
            </DropdownMenu.Content>

        </DropdownMenu.Root>

        // <Select.Root>
        //     <Select.Trigger>
        //             {formatPubKey(user?.publicKey)}
        //     </Select.Trigger>
        //     <Select.Content>
        //         <Select.Item  value="profile" onClick={() => {
        //             setAnchorEl(null);
        //             navigate("/profile");
        //         }}>
        //             <AccountCircle sx={{marginRight: 1}}/>Profile
        //         </Select.Item>
        //         <Select.Item value="logout" onClick={() => {
        //             setAnchorEl(null);
        //             logout();
        //         }}>
        //             <LogoutIcon sx={{marginRight: 1}}/>Log out
        //         </Select.Item>
        //     </Select.Content>
        // </Select.Root>
    )
}
